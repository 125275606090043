import React from 'react'
import { Link } from "react-router-dom"
import Slider from "react-slick"
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from 'react-redux'

function TopLeaders() {
    const Data = useSelector(item => item)
    let dispatch = useDispatch();


   

    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    }

    const leaders = [
        {
            pid: "/profile/dp-01.jpg",
            name: "Jaskeeran Singh",
            percent: "80%"
        },
        {
            pid: "/profile/dp-02.jpg",
            name: "Jaskeeran Sanyal",
            percent: "80%"
        },
        {
            pid: "/profile/dp-03.jpg",
            name: "Eknoor",
            percent: "80%"
        },
        {
            pid: "/profile/dp-04.jpg",
            name: "Nampreet Kaur",
            percent: "80%"
        },
        {
            pid: "/profile/dp-05.jpg",
            name: "Tavleen Singh",
            percent: "80%"
        },
        {
            pid: "/profile/dp-06.jpg",
            name: "Amarjeet",
            percent: "80%"
        },
    ];

    return (
        <>
            <div className='p-3 md:flex'>
                <div className='flex-auto md:pr-5'>
                    <div className='flex list'>
                        <div>
                            <h6>Leaderboard</h6> <div className='text-xs'>People who contributed the most</div>
                        </div>
                        <div>
                            <h6>20</h6> <div className='text-xs'>Top performers</div>
                        </div>
                    </div>
                </div>
                <div className='flex-none w-32 md:text-end md:pr-3 pt-3 md:pt-1 pl-3 md:pl-0'>
                    <Link className='btnMore' to='/leaderboard' onClick={()=>dispatch({type: "TAB" , payload: {tab: 'leaderboard'}})}>View More</Link>
                </div>
            </div>

            <div className='px-8 text-xs leaderC'>
                <Slider {...settings}>
                    {leaders.map((leader) => (
                        <div className='text-center md:text-left'>
                            <div className='inline-flex items-center px-4'>
                                <div className='flex-none w-3rem'>
                                    <span className='dp' style={{ backgroundImage: `url(${(leader.pid)})` }}></span>
                                </div>
                                <div className='flex-auto pl-3 text-left leading-normal'>
                                    <p><strong>{leader.name}</strong></p>
                                    <p>Percentage: {leader.percent}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </>
    )
}

export default TopLeaders