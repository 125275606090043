import * as React from 'react'
import styles from '../../shared/common.module.css'
import FilterSelect from './filter-dropdown'
import ProgressBar from 'react-percent-bar'


function RecognitionFilter() {    

    return (
        <>
            <div className={`${styles.navyBox} rounded-xl`}>
                <div className={`${styles.fltRow} flex gap-0 text-xs`}>
                    <div className='p-4 w-1/2'>
                        <div className='pb-2'>Done Categories</div>
                        <div className='flex gap-3 items-center'>
                            <div className='w-3/4'>
                                <ProgressBar borderColor="#585858" percent="10" fillColor="#44A0DF" width="100%" height="6px" />
                            </div>
                            <div className='w-1/4 text-right'>
                                <strong className='text-primary'>10%</strong>
                            </div>
                        </div>
                    </div>
                    <div className='p-4 w-1/2'>
                        <div className='pb-2'>Category</div>
                        <div className={styles.cDDBoxNonBdr}>
                            <FilterSelect />
                        </div>
                    </div>
                </div>
                <div className={`${styles.fltRow} flex gap-0 text-xs`}>
                    <div className='p-4 w-1/2'>
                        <div className='pb-2'>Done Files</div>
                        <div className='flex gap-3 items-center'>
                            <div className='w-3/4'>
                                <ProgressBar borderColor="#585858" percent="30" fillColor="#44A0DF" width="100%" height="6px" />
                            </div>
                            <div className='w-1/4 text-right'>
                                <strong className='text-primary'>30%</strong>
                            </div>
                        </div>
                    </div>
                    <div className='p-4 w-1/2'>
                        <div className='pb-2'>File name</div>
                        <div className={styles.cDDBoxNonBdr}>
                            <FilterSelect />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RecognitionFilter