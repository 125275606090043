import React from 'react'
import DashCounter from './dash-shared/counter'
import ErrorsPercentage from './dash-shared/error-piechart'
import TopLeaders from './dash-shared/topleaders'
import CategoriesChart from './dash-shared/categories-linechart'
import TopCatList from './dash-shared/topcat-list'
import '../shared/page.css'

function HomeAdmin() {
    return (

        <>

            <div className='dashTopPanel pt-3'>
                <div className='block lg:flex'>
                    <div className='lg:w-2/3'>
                        <DashCounter />
                    </div>
                    <div className='lg:w-1/3'>
                        <ErrorsPercentage />
                    </div>
                </div>
            </div>
            <TopLeaders />
            <div className='block lg:flex'>
                <div className='lg:w-2/3 bgLight'>
                    <CategoriesChart />
                </div>
                <div className='lg:w-1/3'>
                    <TopCatList />
                </div>
            </div>
        </>
    )
}

export default HomeAdmin