import React from 'react'
// import { Link } from "react-router-dom"

function DashCounter() {   

    return (
        <>
            <div className='counterBox flex text-center'>
                <div className='flex-auto px-4 py-6'>
                    <span className='cIco users mb-3'></span>
                    <h3>10000</h3>
                    <p className='m-0 text-xs'>Total no. of Registrations</p>
                </div>
                <div className='flex-auto px-4 py-6'>
                    <span className='cIco cate mb-3'></span>
                    <h3>10000</h3>
                    <p className='m-0 text-xs'>Total no. of Categories</p>
                </div>
                <div className='flex-auto px-4 py-6'>
                    <span className='cIco subcate mb-3'></span>
                    <h3>10000</h3>
                    <p className='m-0 text-xs'>Total no. of Sub-Categories</p>
                </div>
            </div>
        </>
    )
}

export default DashCounter