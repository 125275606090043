import { useMutation } from 'react-query'
import axios from 'axios'
import { BACKEND_URL } from '../config'
import { useDispatch } from 'react-redux';
import { redux_login } from './authSlice';

export const useLogin = () => {
  let dispatch = useDispatch()

    return useMutation(
        {
          mutationFn: async (body) => {
            // setoutputText("")
            return await axios.post(`${BACKEND_URL}/login_user`,
             body,
            {'Content-Type': 'application/json'}
            )
          },
          onSuccess: (data , body) => {
            dispatch(redux_login({email: body.email , name : data.data.name , register_id : data.data.register_id , role : data.data.role , auth_token : data.data.auth_token}) )
          }, 
          onError: (error) => {
            console.log(error.response)
          }
        }
      )
}


export const useRegisterUser = () => {
  let dispatch = useDispatch()

    return useMutation(
        {
          mutationFn: async (body) => {
            // setoutputText("")
            return await axios.post(`${BACKEND_URL}/create_user`,
             body,
            {'Content-Type': 'application/json'}
            )
          },
          onSuccess: (data , body) => {
            dispatch(redux_login({email: body.email , name : data.data.name , register_id : data.data.register_id , role : data.data.role , auth_token : data.data.auth_token}) )
          }, 
          onError: (error) => {
            console.log(error.response)
          }
        }
      )
}


export const useSendotp = () => {

    return useMutation(
        {
          mutationFn: async (body) => {
            // setoutputText("")
            return await axios.post(`${BACKEND_URL}/send_otp`,
             body,
            {'Content-Type': 'application/json'}
            )
          },
          onSuccess: (data) => {

          }, 
          onError: (error) => {
            console.log(error.response)
          }
        }
      )
}

export const useSendOtpRegister = () => {

  return useMutation(
      {
        mutationFn: async (body) => {
          // setoutputText("")
          return await axios.post(`${BACKEND_URL}/send_otp_register`,
           body,
          {'Content-Type': 'application/json'}
          )
        },
        onSuccess: (data) => {

        }, 
        onError: (error) => {
          console.log(error.response)
        }
      }
    )
}

export const useVerifyOtp = () => {

  return useMutation(
      {
        mutationFn: async (body) => {
          // setoutputText("")
          return await axios.post(`${BACKEND_URL}/verify_otp`,
           body,
          {'Content-Type': 'application/json'}
          )
        },
        onSuccess: (data) => {

        }, 
        onError: (error) => {
          console.log(error.response)
        }
      }
    )
}

export const useChangePassword = () => {

  return useMutation(
      {
        mutationFn: async (body) => {
          // setoutputText("")
          return await axios.post(`${BACKEND_URL}/change_password`,
           body,
          {'Content-Type': 'application/json'}
          )
        },
        onSuccess: (data) => {

        }, 
        onError: (error) => {
          console.log(error.response)
        }
      }
    )
}




