import React from 'react'
import { useState, useEffect } from "react"
import "./shared/auth.css"
import { Link } from "react-router-dom"
import SideBox from './shared/sideart-box'
import AuthCopy from './shared/auth-copyright'
import loadGoogleSignInScript from './googleSignInScript'
import { BACKEND_URL } from '../config';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LoaderForAll from '../components/loader-for-all'
import jwt_decode from 'jwt-decode'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import { useLogin } from './hooks'
import { useFormik } from 'formik'
import { LoginSchema } from './authSchema'

function Login() {
    const Data = useSelector(state => state)
    let navigate = useNavigate()
    const login_hook = useLogin()

    const [isPasswordVisible, setisPasswordVisible] = useState(false)

    // async function googleLogin(cred) {

    //     try {


    //         //send otp
    //         let res = await fetch(BACKEND_URL + '/google_login', {
    //             method: "POST",
    //             headers: {
    //                 "Content-Type": "application/json",
    //             },
    //             body: JSON.stringify({
    //                 email: cred.email,
    //                 name: cred.name,

    //             })

    //         })
    //         let resJson = await res.json()
    //         if (res.status == 200) {
    //             dispatch(redux_login({ email: cred.email, name: resJson.name, register_id: resJson.register_id, role: resJson.role, auth_token: resJson.auth_token }))
    //         }
    //         else {
    //             setError(er => (
    //                 {
    //                     ...er,
    //                     err: resJson.message
    //                 }
    //             ))
    //         }
    //         setload(false)
    //     } catch (error) {
    //         console.log(error)
    //         setError(er => (
    //             {
    //                 ...er,
    //                 err: "Error Occured , Try again"
    //             }
    //         ))
    //         setload(false)
    //     }
    // }

    // function handleCallback(res) {
    //     let cred = jwt_decode(res.credential)
    //     googleLogin(cred)
    // }

    // useEffect(() => {
    //     loadGoogleSignInScript()
    //     .then(() => {
    //     // Script loaded successfully
    //     console.log('Google Sign-In script loaded');

    //     window.google.accounts.id.initialize({
    //     client_id: '923774706936-kh33uep37i8m3buhues707qq77ed6qfb.apps.googleusercontent.com',
    //     callback: handleCallback
    //     })
    //     window.google.accounts.id.renderButton(
    //     document.getElementById("signInDiv"),
    //     {theme:'outline' , size:'large', width: 346}
    //     )
    //     })
    //     },[])

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: LoginSchema,
        onSubmit: (values) => {

            login_hook.mutate(values)
        }
    });


    useEffect(() => {
        console.log(login_hook)

        if (login_hook.isError) {
            toast.error(login_hook.error?.response?.data.message)
        }

        else if (login_hook.isSuccess) {
            if (Data.auth.isSignedIn) {
                if (Data.auth.auth_token?.length !== 0) {
                    if (Data.auth.role == "admin") {
                        navigate('/home-admin')
                    }
                    else {
                        navigate('/home-public')
                    }
                }
            }
        }
    }, [login_hook])








    return (
        <>
            <div className='authWrapper relative block md:flex'>
                <div className='flex-none w-full md:w-2/4'>
                    <SideBox />
                </div>
                <div className='grow md:h-screen'>
                    <div className='p-8 md:px-12 h-full md:flex'>
                        <div className='authModBox lg:w-96 max-w-full'>
                            <h2 className='uppercase text-2xl md:text-3xl mb-2'>Login</h2>
                            <p className='text-xs mb-5'>You are invited to be a contributor to this project. Please Login to access the NLP toolkit.</p>
                            <div id="signInDiv"></div>
                            {/* <button type='button' className='btnPrimary text-white font-semibold py-3 w-full'>
                                <i className='gIco mr-3'></i>Sign in with Google
                               
                                
                            </button> */}
                            {/* <div className='orSptr text-sm'>or</div> */}

                            {login_hook.isLoading ? <LoaderForAll /> :

                                <form name='logIn'>
                                    <div className='mb-3'>
                                        <label htmlFor='email' className='text-primary w-full font-semibold'>Email Address</label>
                                        <input onBlur={formik.handleBlur} name='email' id="email" value={formik.values.email} onChange={formik.handleChange} type='text' className={`${(formik.errors.email && formik.touched.email) ? 'error' : ''} w-full inpFld`} placeholder='Enter here' />
                                        {formik.errors.email && formik.touched.email && <small className='error'>{formik.errors.email}</small>}
                                    </div>

                                    <div className='mb-3 relative'>
                                        <label htmlFor='password' className='text-primary w-full font-semibold'>Password</label>
                                        <input onBlur={formik.handleBlur} name='password' id="password" value={formik.values.password} onChange={formik.handleChange} type={isPasswordVisible ? "text" : "password"}
                                            className={`${formik.errors.password && formik.touched.password ? 'error' : ''} w-full inpFld`} placeholder='Enter here' />
                                        {formik.errors.password && formik.touched.password && <small className='error'>{formik.errors.password}</small>}
                                        <button className="toggleBtn" onClick={(e) => {
                                            e.preventDefault()
                                            setisPasswordVisible(p => !p)
                                        }}>
                                            {isPasswordVisible ? 'Hide' : 'Show'}
                                        </button>
                                    </div>
                                    <div className='flex justify-between text-xs mb-5'>
                                        <div>
                                            {/* <input type='checkbox' className='align-middle mr-2' /> Remember Me */}
                                        </div>
                                        <div>
                                            <Link to='/forget-password'>Forgot Password?</Link>
                                        </div>
                                    </div>

                                    <div className='grid grid-cols-2 gap-4'>
                                        <div>
                                            <button onClick={formik.handleSubmit} type='submit' className='btnPrimary text-white font-semibold py-3 w-full'>
                                                Log in
                                            </button>
                                        </div>
                                        <div>
                                            <Link to='/signup' className='btnOutline text-primary font-semibold py-3 w-full'>
                                                Sign up
                                            </Link>
                                        </div>
                                    </div>
                                </form>}
                        </div>
                    </div>
                </div>
                <div className='copyTxt text-sm p-3'>
                    <AuthCopy />
                </div>
            </div>

            <ToastContainer theme="colored" />
        </>
    )
}

export default Login