import React from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
//import _ from 'lodash';

const getOptions = (type) => ({
    legend: {
        layout: 'horizontal',
        align: 'bottom',
        horizontalAlign: 'middle',
        itemMarginTop: 3,
        itemMarginBottom: 3,
        style: {
            fontSize: '8px',
            fill: '#ffffff',
          }
      },
    chart: {
        type,
        width: 960,
        height: 350,     
    },
    title: {
        text: '',
        align: 'top',
        style: {
          fontSize: '10px',
          fill: '#ffffff',
        }
    },
    
     
    credits: {
        enabled: false
   },   
    yAxis: {
        title: {
            text: 'Total number of people',
            x: -10,
            style: {
                color: '#cdcdcd'
            }
        },
        labels: {
            style: {
                color: '#cdcdcd'
            }
        }
    },
    xAxis: {
        labels: {
            style: {
                color: '#cdcdcd'
            }
        },
    },
    colors: ['#44A0DF', '#CA3559'],
    series: [
        {
            borderWidth: 0,
            xAxis: 0,
            yAxis: 0,
            style: {
                fontSize: '8px',
                color: '#cdcdcd',
            },
            data: [
                ['Poem', 245],
                ['Novel', 150],
                ['Article', 390],
                ['Stories', 240],
                ['News', 330],
                ['Gurbani', 140],
                ['Play', 210],
            ],
            showInLegend: false,
            dataLabels: {
                enabled: false,
                allowOverlap: false
            },
            style: {
                fontSize: '8px',
                fontWeight: 300,
                color: '#cdcdcd',
            }
          },
    ],
});

function CategoriesChart() {

    return (
        <>
            <div className='p-4'>
                <h6 className='mb-4'>Progress of categories</h6>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={getOptions('column')}
                />
            </div>

        </>
    )
}

export default CategoriesChart