import * as React from 'react'
import styles from '../shared/common.module.css'
import ClickAwayListener from '@mui/base/ClickAwayListener'
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

function SelectLang({lang , setlang , services , setendpoint}) {
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    const handleClickAway = () => {
        setOpen(false);
    };
    function handleLangChange(newLang) {
        if (services === 'translation'){
            if (newLang ==='punjabi'){
                setlang('punjabi')
                setendpoint('pun2engtranslation')
            }
            else{
                setlang('english')
                setendpoint('eng2puntranslation')
            }
        }
        else if (services === 'transliteration') {
            if (newLang ==='punjabi'){
                setlang('punjabi')
                setendpoint('pun2engtransliteration')
            }
            else{
                setlang('english')
                setendpoint('eng2puntransliteration')
            }
        }
        // else if (services === 'ner'){
        //     if (newLang ==='punjabi'){
        //         setlang('punjabi')
        //         setendpoint()
        //     }
        //     else{
        //         setlang('english')
        //         setendpoint()
        //     }
        // }
        
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className={styles.dropbox}>
                <button type="button" onClick={handleClick}>
                    Select Language <ArrowDropDownOutlinedIcon />
                </button>
                {open ? (
                    <div className={styles.dropped}>
                        <div className={styles.ddItem}>
                            <input checked={lang==='punjabi'} onChange={()=>handleLangChange('punjabi')} type='radio' name="selectname" />
                            <label>Punjabi</label>
                            <CheckCircleOutlineIcon className={styles.nonChk} /> <CheckCircleIcon className={styles.chkd} />
                        </div>
                        <div className={styles.ddItem}>
                            <input checked={lang==='english'} onChange={()=>handleLangChange('english')} type='radio' name="selectname" />
                            <label>English</label>
                            <CheckCircleOutlineIcon className={styles.nonChk} /> <CheckCircleIcon className={styles.chkd} />
                        </div>
                       
                    </div>
                ) : null}
            </div>
        </ClickAwayListener>
    );
}

export default SelectLang