import React from 'react'
import styles from '../shared/common.module.css'
import { Link } from 'react-router-dom'
import AuthCopy from '../../auth/shared/auth-copyright'
import Select from '../services/select'

function Contribution() {

    return (
        <>
           <div className='pageWrap lightWhite withCopy'>
                       

                <div className={`${styles.pagecontainer} ${styles.pxC}`}>
                   

                    <div className='grayBox rounded-xl pb-5'>
                        <div className='text-center'>
                            <div className='inline-block'><img src="/svg/themeIcon.svg" alt="" /></div>
                        </div>
                        <div className={styles.selOptBox}>
                            <h2 className='text-primary mb-3 text-lg'>Select an Option</h2>
                            <ul className='list-none'>
                                <li>
                                    <Link to=''>Named Entity Recognition</Link>
                                </li>
                                <li>
                                    <Link to=''>Part of Speech</Link>
                                </li>
                                <li>
                                    <Link to='/view_corpuses'>Upload Corpus</Link>
                                </li>
                                <li>
                                    <Link to=''>Gurbani</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className='copyFloat'><AuthCopy /></div>          
            </div> 
        </>
    )
}

export default Contribution