import React from 'react'
import LoaderForAll from '../components/loader-for-all'
import { useState } from 'react'
import { BACKEND_URL } from '../config'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { redux_logout } from './authSlice'

export function LogoutPopup({setpop}) {

    const [load, setload] = useState(false)
    const Data = useSelector(state => state)
    let navigate = useNavigate()
    let dispatch = useDispatch()

    async function handleLogout(e) {
        e.preventDefault()
        try {
            setload(true)
            
            
            //send otp
            let res = await fetch(BACKEND_URL + '/logout' , {
                method : "POST" , 
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer ' +  Data.auth.access_token
                }
            })
            let resJson = await res.json()
            localStorage.clear()
            dispatch(redux_logout())
            navigate('/')
            
            

            setload(false)

        } 
        catch (error) {
          localStorage.clear()
          dispatch(redux_logout())
          navigate('/')
            setload(false)
        }
    }


    return (
        <>
        {load ? <LoaderForAll /> : 
        
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                {/* <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">  
                  <h3 className="text-3xl font-semibold text-primary">
                    Modal Title
                  </h3>                
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black float-right leading-none font-semibold outline-none focus:outline-none"
                    // onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black h-6 w-6 opacity-75 text-3xl block outline-none focus:outline-none">&times;</span>
                  </button>
                </div> */}
                {/*body*/}
                <div className="relative py-2 px-4 flex-auto">
                  <p className="my-4 text-black text-lg leading-relaxed">
                    Are you sure want to logout ?
                  </p>
                </div>

                <div className='flex justify-center items-center pb-4'>
                    <div className='px-2'>
                        <button type='button' className="px-4 btnPrimary text-white font-semibold py-2 outline-none focus:outline-none" onClick={handleLogout}>Yes</button>
                    </div>
                    <div className='px-2'>
                        <button className="p-1 ml-auto bg-transparent border-0 text-black float-right leading-none font-semibold outline-none focus:outline-none" onClick={()=> setpop(false)}>No</button>
                    </div>
                </div>
               
                
                
              </div>
            </div>
          </div>
}
          <div className="opacity-75 fixed inset-0 z-40 bg-black"></div>
        </>
    )
}

