import React , {useEffect  } from 'react'
import './newKeyboard.css'


const NewKeyboard = () =>{
    var a = {};
    a.util = {
      mobile: /Android|webOS|iPhone|iPad|iPod|IEMobile|Opera Mini/i.test(navigator.userAgent),
      
      mozilla: (typeof InstallTrigger !== "undefined"),
      windowWidth: function () {
        var i = window.document.documentElement.clientWidth
          , e = window.document.body;
        return window.document.compatMode === "CSS1Compat" && i || e && e.clientWidth || i
      },
      code: function (i) {
        if (!i) {
          var i = window.event
        }
        if (i.code != undefined && i.key != undefined) {
          return i.code
        }
        return "Unidentified"
      },
      keyCode: function (u) {
        if (!u) {
          var u = window.event
        }
        var i = u.keyCode;
        if (this.mozilla) {
          switch (i) {
            case 59:
              i = 186;
              break;
            case 107:
              i = 187;
              break;
            case 109:
              i = 189;
              break;
            case 61:
              i = 187;
              break;
            case 173:
              i = 189;
              break
          }
        }
        if (this.opera) {
          switch (i) {
            case 59:
              i = 186;
              break;
            case 61:
              i = 187;
              break;
            case 109:
              i = 189;
              break
          }
        }
        if (i == 18 && u.location && u.location == 2) {
          i = 255
        }
        return i
      },
      preventDefault: function (i) {
        if (!i) {
          var i = window.event
        }
        i.preventDefault ? i.preventDefault() : (i.returnValue = false)
      },
      srcId: function (i, u, w) {
        if (!i) {
          var i = window.event
        }
        var v;
        if (i.target) {
          v = i.target
        } else {
          if (i.srcElement) {
            v = i.srcElement
          }
        }
        // if (v.nodeType == 3) {
        //   v = target.parentNode
        // }
        while (v.tagName.toLowerCase() != w) {
          v = v.parentNode;
          if (v == u || v.tagName.toLowerCase() == "body") {
            return null
          }
        }
        return v.id
      },
      insertAtCaret: function (i, x) {
        var w = this.getSelectionStart(i);
        var u = this.getSelectionEnd(i);
        var v = i.value.length;
        i.value = i.value.substring(0, w) + x + i.value.substring(u, v);
        this.setCaretPosition(i, w + x.length, 0)
      },
      deleteAtCaret: function (v, u, i) {
        var y = this.getSelectionStart(v);
        var w = this.getSelectionEnd(v);
        var x = v.value.length;
        if (u > y) {
          u = y
        }
        if (w + i > x) {
          i = x - w
        }
        var z = v.value.substring(y - u, w + i);
        v.value = v.value.substring(0, y - u) + v.value.substring(w + i);
        this.setCaretPosition(v, y - u, 0);
        return z
      },
      getSelectionStart: function (i) {
        i.focus();
        if (i.selectionStart !== undefined) {
          return i.selectionStart
        } else {
          if (document.selection) {
            var u = document.selection.createRange();
            if (u == null) {
              return 0
            }
            var w = i.createTextRange();
            var v = w.duplicate();
            w.moveToBookmark(u.getBookmark());
            v.setEndPoint("EndToStart", w);
            return v.text.length
          }
        }
        return 0
      },
      getSelectionEnd: function (i) {
        i.focus();
        if (i.selectionEnd !== undefined) {
          return i.selectionEnd
        } else {
          if (document.selection) {
            var u = document.selection.createRange();
            if (u == null) {
              return 0
            }
            var w = i.createTextRange();
            var v = w.duplicate();
            w.moveToBookmark(u.getBookmark());
            v.setEndPoint("EndToStart", w);
            return v.text.length + u.text.length
          }
        }
        return i.value.length
      },
      setCaretPosition: function (u, w, i) {
        var v = u.value.length;
        if (w > v) {
          w = v
        }
        if (w + i > v) {
          i = v - i
        }
        u.focus();
        if (u.setSelectionRange) {
          u.setSelectionRange(w, w + i)
        } else {
          if (u.createTextRange) {
            var x = u.createTextRange();
            x.collapse(true);
            x.moveEnd("character", w + i);
            x.moveStart("character", w);
            x.select()
          }
        }
        u.focus()
      },
      selectAll: function (i) {
        this.setCaretPosition(i, 0, i.value.length)
      },
      fromCharCodeS: function () {
        var w = arguments.length;
        var x = "";
        var e, v, y;
        for (var u = 0; u < w; u++) {
          y = arguments[u];
          if (y < 1114112 && 65535 < y) {
            e = Math.floor((y - 65536) / 1024) + 55296;
            v = ((y - 65536) % 1024) + 56320;
            x = x + String.fromCharCode(e, v)
          } else {
            if (y < 65536) {
              x = x + String.fromCharCode(y)
            }
          }
        }
        return x
      }
    };
    a.layout = function () {
      this.keys = [];
      this.deadkeys = [];
      this.dir = "ltr";
      this.name = "US";
      this.lang = "en"
    }
      ;
    a.layout.prototype.loadDefault = function () {
      this.keys = [{
        i: "k0",
        c: "0",
        n: "`",
        s: "~"
      }, {
        i: "k1",
        c: "0",
        n: "1",
        s: "!"
      }, {
        i: "k2",
        c: "0",
        n: "2",
        s: "@"
      }, {
        i: "k3",
        c: "0",
        n: "3",
        s: "#"
      }, {
        i: "k4",
        c: "0",
        n: "4",
        s: "$"
      }, {
        i: "k5",
        c: "0",
        n: "5",
        s: "%"
      }, {
        i: "k6",
        c: "0",
        n: "6",
        s: "^"
      }, {
        i: "k7",
        c: "0",
        n: "7",
        s: "&"
      }, {
        i: "k8",
        c: "0",
        n: "8",
        s: "*"
      }, {
        i: "k9",
        c: "0",
        n: "9",
        s: "("
      }, {
        i: "k10",
        c: "0",
        n: "0",
        s: ")"
      }, {
        i: "k11",
        c: "0",
        n: "-",
        s: "_"
      }, {
        i: "k12",
        c: "0",
        n: "=",
        s: "+"
      }, {
        i: "k13",
        c: "1",
        n: "q",
        s: "Q"
      }, {
        i: "k14",
        c: "1",
        n: "w",
        s: "W"
      }, {
        i: "k15",
        c: "1",
        n: "e",
        s: "E"
      }, {
        i: "k16",
        c: "1",
        n: "r",
        s: "R"
      }, {
        i: "k17",
        c: "1",
        n: "t",
        s: "T"
      }, {
        i: "k18",
        c: "1",
        n: "y",
        s: "Y"
      }, {
        i: "k19",
        c: "1",
        n: "u",
        s: "U"
      }, {
        i: "k20",
        c: "1",
        n: "i",
        s: "I"
      }, {
        i: "k21",
        c: "1",
        n: "o",
        s: "O"
      }, {
        i: "k22",
        c: "1",
        n: "p",
        s: "P"
      }, {
        i: "k23",
        c: "0",
        n: "[",
        s: "{"
      }, {
        i: "k24",
        c: "0",
        n: "]",
        s: "}"
      }, {
        i: "k25",
        c: "0",
        n: "\\",
        s: "|"
      }, {
        i: "k26",
        c: "1",
        n: "a",
        s: "A"
      }, {
        i: "k27",
        c: "1",
        n: "s",
        s: "S"
      }, {
        i: "k28",
        c: "1",
        n: "d",
        s: "D"
      }, {
        i: "k29",
        c: "1",
        n: "f",
        s: "F"
      }, {
        i: "k30",
        c: "1",
        n: "g",
        s: "G"
      }, {
        i: "k31",
        c: "1",
        n: "h",
        s: "H"
      }, {
        i: "k32",
        c: "1",
        n: "j",
        s: "J"
      }, {
        i: "k33",
        c: "1",
        n: "k",
        s: "K"
      }, {
        i: "k34",
        c: "1",
        n: "l",
        s: "L"
      }, {
        i: "k35",
        c: "0",
        n: ";",
        s: ":"
      }, {
        i: "k36",
        c: "0",
        n: "'",
        s: '"'
      }, {
        i: "k37",
        c: "1",
        n: "z",
        s: "Z"
      }, {
        i: "k38",
        c: "1",
        n: "x",
        s: "X"
      }, {
        i: "k39",
        c: "1",
        n: "c",
        s: "C"
      }, {
        i: "k40",
        c: "1",
        n: "v",
        s: "V"
      }, {
        i: "k41",
        c: "1",
        n: "b",
        s: "B"
      }, {
        i: "k42",
        c: "1",
        n: "n",
        s: "N"
      }, {
        i: "k43",
        c: "1",
        n: "m",
        s: "M"
      }, {
        i: "k44",
        c: "0",
        n: ",",
        s: "<"
      }, {
        i: "k45",
        c: "0",
        n: ".",
        s: ">"
      }, {
        i: "k46",
        c: "0",
        n: "/",
        s: "?"
      }, {
        i: "k47",
        c: "0",
        n: "\\",
        s: "|"
      }];
      this.deadkeys = [];
      this.dir = "ltr";
      this.name = "US";
      this.lang = "en"
    }
      ;
    a.layout.prototype.load = function (e) {
      this.keys = e.keys;
      this.deadkeys = e.deadkeys;
      this.dir = e.dir;
      this.name = e.name;
      this.lang = e.lang ? e.lang : "en"
    }
      ;
    a.layout.parser = {
      keys: {
        "192": "Backquote",
        "49": "Digit1",
        "50": "Digit2",
        "51": "Digit3",
        "52": "Digit4",
        "53": "Digit5",
        "54": "Digit6",
        "55": "Digit7",
        "56": "Digit8",
        "57": "Digit9",
        "48": "Digit0",
        "189": "Minus",
        "187": "Equal",
        "81": "KeyQ",
        "87": "KeyW",
        "69": "KeyE",
        "82": "KeyR",
        "84": "KeyT",
        "89": "KeyY",
        "85": "KeyU",
        "73": "KeyI",
        "79": "KeyO",
        "80": "KeyP",
        "219": "BracketLeft",
        "221": "BracketRight",
        "220": "Backslash",
        "65": "KeyA",
        "83": "KeyS",
        "68": "KeyD",
        "70": "KeyF",
        "71": "KeyG",
        "72": "KeyH",
        "74": "KeyJ",
        "75": "KeyK",
        "76": "KeyL",
        "186": "Semicolon",
        "222": "Quote",
        "90": "KeyZ",
        "88": "KeyX",
        "67": "KeyC",
        "86": "KeyV",
        "66": "KeyB",
        "78": "KeyN",
        "77": "KeyM",
        "188": "Comma",
        "190": "Period",
        "191": "Slash",
        "17": "ControlLeft",
        "18": "AltLeft",
        "16": "ShiftLeft",
        "32": "Space",
        "27": "Escape",
        "20": "CapsLock",
        "13": "Enter",
        "255": "AltRight"
      },
      codes: {
        Backquote: 0,
        Digit1: 1,
        Digit2: 2,
        Digit3: 3,
        Digit4: 4,
        Digit5: 5,
        Digit6: 6,
        Digit7: 7,
        Digit8: 8,
        Digit9: 9,
        Digit0: 10,
        Minus: 11,
        Equal: 12,
        KeyQ: 13,
        KeyW: 14,
        KeyE: 15,
        KeyR: 16,
        KeyT: 17,
        KeyY: 18,
        KeyU: 19,
        KeyI: 20,
        KeyO: 21,
        KeyP: 22,
        BracketLeft: 23,
        BracketRight: 24,
        Backslash: 25,
        KeyA: 26,
        KeyS: 27,
        KeyD: 28,
        KeyF: 29,
        KeyG: 30,
        KeyH: 31,
        KeyJ: 32,
        KeyK: 33,
        KeyL: 34,
        Semicolon: 35,
        Quote: 36,
        KeyZ: 37,
        KeyX: 38,
        KeyC: 39,
        KeyV: 40,
        KeyB: 41,
        KeyN: 42,
        KeyM: 43,
        Comma: 44,
        Period: 45,
        Slash: 46,
        IntlBackslash: 47
      },
      getKeyLegend: function (v, u) {
        var w = v.length;
        for (var e = 0; e < w; e++) {
          if (v[e].i == u) {
            return (v[e].n ? v[e].n : "")
          }
        }
        return 0
      },
      getKey: function (v, u) {
        var w = v.length;
        for (var e = 0; e < w; e++) {
          if (v[e].i == u) {
            return v[e]
          }
        }
        return null
      },
      isDeadkey: function (e, w) {
        if (!e) {
          return false
        }
        var v = e.length;
        for (var u = 0; u < v; u++) {
          if (e[u].k == w) {
            return true
          }
        }
        return false
      },
      getMappedValue: function (e, x, w) {
        if (!e) {
          return ""
        }
        var v = e.length;
        for (var u = 0; u < v; u++) {
          if (e[u].k == w && e[u].b == x) {
            return e[u].c
          }
        }
        return ""
      },
      getState: function (w, e, x, u, v, i) {
        var y = "n";
        if (!e && !x && w) {
          y = "n"
        } else {
          if (!e && x && !w) {
            y = "s"
          } else {
            if (!e && x && w) {
              y = "s"
            } else {
              if (e && !x && !w) {
                y = "n"
              } else {
                if (e && !x && w) {
                  y = "t"
                } else {
                  if (e && x && !w) {
                    y = "s"
                  } else {
                    if (e && x && w) {
                      y = "f"
                    }
                  }
                }
              }
            }
          }
        }
        if (u) {
          if (v == "1") {
            if (y == "n") {
              y = "s"
            } else {
              if (y == "s") {
                y = "n"
              }
            }
          } else {
            if (v == "SGCap") {
              if (y == "n") {
                y = "y"
              } else {
                if (y == "s") {
                  y = "z"
                }
              }
            }
          }
        }
        if (i) {
          if (y == "n") {
            y = "t"
          } else {
            if (y == "s") {
              y = "f"
            }
          }
        }
        return y
      }
    };
    a.keyboard = function (e, w) {
      this.defaultLayout = new a.layout();
      this.defaultLayout.loadDefault();
      this.virtualLayout = new a.layout();
      this.virtualLayout.loadDefault();
      this.currentLayout = this.virtualLayout;
      this.shift = false;
      this.shiftOn = false;
      this.caps = false;
      this.capsOn = false;
      this.alt = false;
      this.altGr = false;
      this.altGrOn = false;
      this.ctrl = false;
      this.altCtrlOn = false;
      this.fontSize = 18;
      this.counter = 0;
      this.interval = 0;
      this.prev = "";

      this.cancelkeypress = false;
      this.customOnBackspace = function (i) { }
        ;
      this.customOnEnter = function () { }
        ;
      this.customOnSpace = function () {
        return false
      }
        ;
      this.customOnKey = function (i) {
        return false
      }
        ;
      this.customOnEsc = function () { }
        ;
      this.customDrawKeyboard = function (i) {
        return i
      }
        ;
      this.textbox = document.getElementById("editor")
      this.textbox.style.fontSize = "18px";
      if (a.util.mobile) {
        this.textbox.readOnly = true
      }
      var v = ['<div id="branah-keyboard">'];
      v.push('<div id="branah-keyboard-alpha">');
      if (a.util.windowWidth() < 640) {
        for (var u = 13; u < 23; u++) {
          v.push('<button id="branah-k', u, '" class="branah-key"></button>')
        }
        for (var u = 26; u < 35; u++) {
          v.push('<button id="branah-k', u, '" class="branah-key"></button>')
        }
        for (var u = 37; u < 44; u++) {
          v.push('<button id="branah-k', u, '" class="branah-key"></button>')
        }
        v.push('<button id="branah-left-shift"><span>Shift</span></button>');
        v.push('<button id="branah-caps-lock"><span>Caps</span></button>');
        v.push('<button id="branah-escape" title="Turn on/off keyboard input conversion"><span>Esc</span></button>');
        v.push('<button id="branah-space"><span>Space</span></button>');
        v.push('<button id="branah-enter" class="branah-enter"><span>Enter</span></button>');
        v.push('<button id="branah-left-ctrl" style="display:none"><span>Ctrl</span></button>');
        v.push('<button id="branah-left-alt" style="display:none"><span>Alt</span></button>');
        v.push('<button id="branah-right-alt"><span>AltGr</span></button>');
        v.push('<button id="branah-right-ctrl" style="display:none"><span>Ctrl</span></button>');
        v.push('<button id="branah-right-shift" style="display:none"><span>Shift</span></button>');
        v.push('<button id="branah-backspace"><span>Back</span></button>');
        for (var u = 0; u < 13; u++) {
          v.push('<button id="branah-k', u, '" class="branah-key"></button>')
        }
        for (var u = 23; u < 26; u++) {
          v.push('<button id="branah-k', u, '" class="branah-key"></button>')
        }
        for (var u = 35; u < 37; u++) {
          v.push('<button id="branah-k', u, '" class="branah-key"></button>')
        }
        for (var u = 44; u < 48; u++) {
          v.push('<button id="branah-k', u, '" class="branah-key"></button>')
        }
        v.push('<div class="branah-clear"></div>')
      } else {
        for (var u = 0; u < 13; u++) {
          v.push('<button id="branah-k', u, '" class="branah-key"></button>')
        }
        v.push('<button id="branah-backspace"><span>Backspace</span></button>');
        v.push('<div class="branah-clear"></div>');
        v.push('<button id="branah-tab"><span>Tab</span></button>');
        for (var u = 13; u < 25; u++) {
          v.push('<button id="branah-k', u, '" class="branah-key"></button>')
        }
        v.push('<button id="branah-k25"></button>');
        v.push('<div class="branah-clear"></div>');
        v.push('<button id="branah-caps-lock"><span>Caps Lock</span></button>');
        for (var u = 26; u < 37; u++) {
          v.push('<button id="branah-k', u, '" class="branah-key"></button>')
        }
        v.push('<button id="branah-enter" class="branah-enter"><span>Enter</span></button>');
        v.push('<div class="branah-clear"></div>');
        v.push('<button id="branah-left-shift"><span>Shift</span></button>');
        v.push('<button id="branah-k47" class="branah-key"></button>');
        for (var u = 37; u < 47; u++) {
          v.push('<button id="branah-k', u, '" class="branah-key"></button>')
        }
        v.push('<button id="branah-right-shift"><span>Shift</span></button>');
        v.push('<div class="branah-clear"></div>');
        v.push('<button id="branah-left-ctrl"><span>Ctrl</span></button>');
        v.push('<button id="branah-left-alt"><span>Alt</span></button>');
        v.push('<button id="branah-space"><span>Space</span></button>');
        v.push('<button id="branah-right-alt"><span>AltGr</span></button>');
        v.push('<button id="branah-escape" title="Turn on/off keyboard input conversion"><span>Esc</span></button>');
        v.push('<button id="branah-right-ctrl"><span>Ctrl</span></button>');
        v.push('<div class="branah-clear"></div>')
      }
      v.push("</div>");
      v.push("</div>");
      v.push("</div>");
      document.getElementById(e).innerHTML = v.join("");
      this.wireEvents();
      this.drawKeyboard()
    }
      ;
    a.keyboard.prototype.loadDefaultLayout = function (e) {
      this.defaultLayout.load(e);
      this.drawKeyboard()
    }
      ;
    a.keyboard.prototype.loadVirtualLayout = function (e) {
      this.virtualLayout.load(e);
      this.drawKeyboard();
      this.textbox.style.direction = this.attr("dir")
    }
      ;
    a.keyboard.prototype.switchLayout = function () {
      this.currentLayout = (this.currentLayout === this.defaultLayout) ? this.virtualLayout : this.defaultLayout;
      this.reset();
      this.drawKeyboard();
      this.textbox.style.direction = this.attr("dir")
    }
      ;
    a.keyboard.prototype.getFontSize = function () {
      return this.fontSize
    }
      ;
    a.keyboard.prototype.setFontSize = function (e) {
      this.fontSize = e;
      this.textbox.style.fontSize = this.fontSize + "px"
    }
      ;
    a.keyboard.prototype.onEsc = function () {
      this.switchLayout();
      this.customOnEsc()
    }
      ;
    a.keyboard.prototype.onShift = function () {
      this.shift = !this.shift;
      this.drawKeyboard()
    }
      ;
    a.keyboard.prototype.onAlt = function () {
      this.alt = !this.alt;
      this.drawKeyboard()
    }
      ;
    a.keyboard.prototype.onAltGr = function () {
      this.altGr = !this.altGr;
      this.drawKeyboard()
    }
      ;
    a.keyboard.prototype.onCtrl = function () {
      this.ctrl = !this.ctrl;
      this.drawKeyboard()
    }
      ;
    a.keyboard.prototype.onCapsLock = function () {
      this.caps = !this.caps;
      this.drawKeyboard()
    }
      ;
    a.keyboard.prototype.onBackspace = function () {
      if (this.prev != "") {
        this.prev = "";
        this.shift = false;
        this.drawKeyboard()
      } else {
        var i = a.util.deleteAtCaret(this.textbox, 1, 0);
        if (i.length > 0) {
          var e = i.charCodeAt(0);
          if (e < 57344 && 56319 < e) {
            i = a.util.deleteAtCaret(this.textbox, 1, 0) + i
          }
        }
        this.customOnBackspace(i)
      }
    }
      ;
    a.keyboard.prototype.onEnter = function () {
      a.util.insertAtCaret(this.textbox, "\u000A");
      this.customOnEnter()
    }
      ;
    a.keyboard.prototype.onSpace = function () {
      if (!this.customOnSpace()) {
        a.util.insertAtCaret(this.textbox, "\u0020")
      }
    }
      ;




    a.keyboard.prototype.attr = function (e) {
      if (e == "dir") {
        return this.currentLayout.dir
      } else {
        if (e == "lang") {
          return this.currentLayout.lang
        } else {
          if (e == "name") {
            return this.currentLayout.name
          }
        }
      }
      return ""
    }
      ;
    a.keyboard.prototype.reset = function () {
      this.shift = false;
      this.caps = false;
      this.alt = false;
      this.altGr = false;
      this.ctrl = false;
      this.counter = 0;
      this.interval = 0;
      this.prev = ""
    }
      ;
    a.keyboard.prototype.stopRepeat = function () {
      if (this.interval != 0) {
        clearInterval(this.interval);
        this.counter = 0;
        this.interval = 0
      }
    }
      ;
    a.keyboard.prototype.onKey = function (i) {
      var e = a.layout.parser.getKey(this.currentLayout.keys, i);
      if (e) {
        var v = a.layout.parser.getState(this.ctrl, this.alt, this.shift, this.caps, e.c ? e.c : "0", this.altGr);
        var w = e[v] ? e[v] : "";
        if (this.prev != "") {
          var u = a.layout.parser.getMappedValue(this.currentLayout.deadkeys, w, this.prev);
          if (u != "") {
            a.util.insertAtCaret(this.textbox, u)
          }
          this.prev = ""
        } else {
          if (a.layout.parser.isDeadkey(this.currentLayout.deadkeys, w)) {
            this.prev = w
          } else {
            if (w != "") {
              if (!this.customOnKey(w)) {
                a.util.insertAtCaret(this.textbox, w)
              }
            }
          }
        }
      }
    }
      ;
    a.keyboard.prototype.drawKeyboard = function () {

      if (!this.currentLayout.keys) {
        return
      }
      var F, H, K, L;
      var I = this.currentLayout.keys.length;
      for (var G = 0; G < I; G++) {
        H = this.currentLayout.keys[G];
        if (!document.getElementById("branah-" + H.i)) {
          continue
        }
        var E = this.ctrl;
        var e = this.alt;
        var J = this.shift;
        var D = this.caps;
        var u = this.altGr;
        if (this.shiftOn) {
          J = true
        }
        if (this.capsOn) {
          D = true
        }
        if (this.altCtrlOn) {
          E = true;
          e = true
        }
        if (this.altGrOn) {
          u = true
        }
        K = a.layout.parser.getState(E, e, J, D, H.c ? H.c : "0", u);
        L = H[K] ? H[K] : "";
        if (this.prev != "") {
          L = a.layout.parser.getMappedValue(this.currentLayout.deadkeys, L, this.prev)
        }
        F = [];
        F.push('<div class="branah-label-reference">', a.layout.parser.getKeyLegend(this.defaultLayout.keys, H.i), "</div>");
        if (!J) {
          L = this.customDrawKeyboard(L);
          if (L == "") {
            L = "&nbsp;"
          }
          F.push('<div class="branah-label-natural" style="font-size:', this.fontSize, 'px;">&nbsp;', L, "</div>")
        } else {
          if (L == "") {
            L = "&nbsp;"
          }
          F.push('<div class="branah-label-shift" style="font-size:', this.fontSize, 'px;">&nbsp;', L, "</div>")
        }
        document.getElementById("branah-" + H.i).innerHTML = F.join("")
      }
      var y = document.getElementById("branah-left-ctrl");
      var B = document.getElementById("branah-right-ctrl");
      if (y && B) {
        if (E) {
          y.className = "branah-recessed" + (this.ctrl ? "" : "-hover");
          B.className = "branah-recessed" + (this.ctrl ? "" : "-hover")
        } else {
          y.className = "";
          B.className = ""
        }
      }
      var x = document.getElementById("branah-left-alt");
      if (x) {
        if (e) {
          x.className = "branah-recessed" + (this.alt ? "" : "-hover")
        } else {
          x.className = ""
        }
      }
      var A = document.getElementById("branah-right-alt");
      if (A) {
        if (u) {
          A.className = "branah-recessed" + (this.altGr ? "" : "-hover")
        } else {
          A.className = ""
        }
      }
      var z = document.getElementById("branah-left-shift");
      var C = document.getElementById("branah-right-shift");
      if (z && C) {
        if (J) {
          z.className = "branah-recessed" + (this.shift ? "" : "-hover");
          C.className = "branah-recessed" + (this.shift ? "" : "-hover")
        } else {
          z.className = "";
          C.className = ""
        }
      }
      var v = document.getElementById("branah-caps-lock");
      if (v) {
        if (D) {
          v.className = "branah-recessed" + (this.caps ? "" : "-hover")
        } else {
          v.className = ""
        }
      }
      var w = document.getElementById("branah-escape");
      if (w) {
        if (this.currentLayout === this.defaultLayout) {
          w.className = "branah-recessed"
        } else {
          w.className = ""
        }
      }
    }
      ;
    a.keyboard.prototype.wireEvents = function () {
      var e = this;
      document.getElementById("branah-keyboard").onmousedown = function (i) {
        var u = a.util.srcId(i, this, "button");
        if (!u) {
          return
        }
        e.interval = setInterval(function () {
          e.counter++;
          if (e.counter > 5) {
            switch (u) {
              case "branah-backspace":
                e.onBackspace();
                break;
              case "branah-enter":
                e.onEnter();
                break;

              default:
                if (u.search("^branah-k([0-9]|[1-3][0-9]|4[0-7])$") != -1) {
                  e.onKey(u.substr(7));
                  e.shift = false;
                  e.alt = false;
                  e.ctrl = false;
                  e.altGr = false;
                  e.drawKeyboard()
                }
                break
            }
          }
        }, 50)
      }
        ;
      document.getElementById("branah-keyboard").onmouseup = function (i) {
        e.stopRepeat()
      }
        ;
      document.getElementById("branah-keyboard").onmouseout = function (i) {
        e.stopRepeat()
      }
        ;
      document.getElementById("branah-keyboard").onclick = function (i) {
        var u = a.util.srcId(i, this, "button");
        if (!u) {
          return
        }
        switch (u) {
          case "branah-left-shift":
          case "branah-right-shift":
            e.onShift();
            break;
          case "branah-left-alt":
            e.onCtrl();
            e.onAlt();
            break;
          case "branah-right-alt":
            e.onAltGr();
            break;
          case "branah-left-ctrl":
          case "branah-right-ctrl":
            e.onAlt();
            e.onCtrl();
            break;
          case "branah-escape":
            e.onEsc();
            break;
          case "branah-caps-lock":
            e.onCapsLock();
            break;
          case "branah-backspace":
            e.onBackspace();
            break;
          case "branah-enter":
            e.onEnter();
            break;

          case "branah-space":
            e.onSpace();
            break;


          default:
            if (u.search("^branah-k([0-9]|[1-3][0-9]|4[0-7])$") != -1) {
              e.onKey(u.substr(7));
              e.shift = false;
              e.alt = false;
              e.ctrl = false;
              e.altGr = false;
              e.drawKeyboard()
            } else {

            }
            break
        }
      }
        ;
      if (!("ontouchstart" in document.documentElement) || !a.util.mobile) {
        document.getElementById("branah-left-shift").onmouseover = function (i) {
          e.shiftOn = true;
          e.drawKeyboard()
        }
          ;
        document.getElementById("branah-right-shift").onmouseover = function (i) {
          e.shiftOn = true;
          e.drawKeyboard()
        }
          ;
        document.getElementById("branah-left-shift").onmouseout = function (i) {
          e.shiftOn = false;
          e.drawKeyboard()
        }
          ;
        document.getElementById("branah-right-shift").onmouseout = function (i) {
          e.shiftOn = false;
          e.drawKeyboard()
        }
          ;
        document.getElementById("branah-left-ctrl").onmouseover = function (i) {
          e.altCtrlOn = true;
          e.drawKeyboard()
        }
          ;
        document.getElementById("branah-right-ctrl").onmouseover = function (i) {
          e.altCtrlOn = true;
          e.drawKeyboard()
        }
          ;
        document.getElementById("branah-left-ctrl").onmouseout = function (i) {
          e.altCtrlOn = false;
          e.drawKeyboard()
        }
          ;
        document.getElementById("branah-right-ctrl").onmouseout = function (i) {
          e.altCtrlOn = false;
          e.drawKeyboard()
        }
          ;
        document.getElementById("branah-left-alt").onmouseover = function (i) {
          e.altCtrlOn = true;
          e.drawKeyboard()
        }
          ;
        document.getElementById("branah-right-alt").onmouseover = function (i) {
          e.altGrOn = true;
          e.drawKeyboard()
        }
          ;
        document.getElementById("branah-left-alt").onmouseout = function (i) {
          e.altCtrlOn = false;
          e.drawKeyboard()
        }
          ;
        document.getElementById("branah-right-alt").onmouseout = function (i) {
          e.altGrOn = false;
          e.drawKeyboard()
        }
          ;
        document.getElementById("branah-caps-lock").onmouseover = function (i) {
          e.capsOn = true;
          e.drawKeyboard()
        }
          ;
        document.getElementById("branah-caps-lock").onmouseout = function (i) {
          e.capsOn = false;
          e.drawKeyboard()
        }
      }
      e.textbox.onkeydown = function (u) {
        var i = a.util.code(u);
        if (i == "Unidentified") {
          var w = a.util.keyCode(u);
          i = a.layout.parser.keys[w + ""]
        }
        if ((i == "KeyA" || i == "KeyY" || i == "KeyZ" || i == "KeyC" || i == "KeyV" || i == "KeyX") && (e.ctrl && !e.alt && !e.shift)) {
          return
        }
        if (e.currentLayout == e.defaultLayout && i != "Escape") {
          return
        }
        switch (i) {
          case "ControlLeft":
          case "ControlRight":
            e.ctrl = false;
            e.onCtrl();
            break;
          case "AltLeft":
            e.alt = false;
            e.onAlt();
            break;
          case "AltRight":
            e.altGr = false;
            e.onAltGr();
            break;
          case "ShiftLeft":
          case "ShiftRight":
            e.shift = false;
            e.onShift();
            break;
          case "Escape":
            e.onEsc();
            break;
          case "CapsLock":
            if (u.getModifierState && u.getModifierState("CapsLock")) {
              e.caps = false
            }
            e.onCapsLock();
            break;
          case "Backspace":
            e.onBackspace();
            a.util.preventDefault(u);
            break;
          case "Space":
            e.onSpace();
            a.util.preventDefault(u);
            break;
          case "Enter":
            e.onEnter();
            a.util.preventDefault(u);
            break;
          default:
            var v = a.layout.parser.codes[i];
            if (v != undefined) {
              e.onKey("k" + v);
              e.drawKeyboard();
              a.util.preventDefault(u);
              e.cancelkeypress = true
            }
            break
        }
      }
        ;
      if (a.util.opera) {
        e.textbox.onkeypress = function (i) {
          if (e.cancelkeypress) {
            a.util.preventDefault(i);
            e.cancelkeypress = false
          }
        }
      }
      e.textbox.onkeyup = function (u) {
        var i = a.util.code(u);
        if (i == "Unidentified") {
          var v = a.util.keyCode(u);
          i = a.layout.parser.keys[v + ""]
        }
        switch (i) {
          case "ControlLeft":
          case "ControlRight":
            e.ctrl = true;
            e.onCtrl();
            break;
          case "AltLeft":
            e.alt = true;
            e.onAlt();
            break;
          case "AltRight":
            e.altGr = true;
            e.onAltGr();
            break;
          case "ShiftLeft":
          case "ShiftRight":
            e.shift = true;
            e.onShift();
            break;
          default:
        }
      }
    }
      ;
    var o = true;
    var f = true
      ;
    var g = null;
    var n = {
      undo: [],
      redo: [],
      layout: null,
      fontSize: 16
    };
    var t = "punjabi";
    g = new a.keyboard("keyboard", "editor");
    var s = g.textbox;
    s.focus();
    if (o && f) {
      var p = JSON.parse(localStorage.getItem(t));
      if (p != null) {
        if (p.layout) {
          n.layout = p.layout
        }
        if (p.undo) {
          n.undo = p.undo
        }
        if (p.redo) {
          n.redo = p.redo
        }
        if (p.fontSize) {
          n.fontSize = p.fontSize
        }
      }
    }
    if (n.fontSize) {
      g.setFontSize(n.fontSize)
    }
    var k = [{
      Id: "Standard",
      Name: "Punjabi Keyboard",
      Json: {
        name: "Punjabi",
        dir: "ltr",
        keys: [{
          i: "k0",
          c: "0",
          n: "`"
        }, {
          i: "k1",
          c: "0",
          n: "1",
          t: "੧"
        }, {
          i: "k2",
          c: "0",
          n: "2",
          t: "੨"
        }, {
          i: "k3",
          c: "0",
          n: "3",
          t: "੩"
        }, {
          i: "k4",
          c: "0",
          n: "4",
          s: "ੱ",
          t: "੪"
        }, {
          i: "k5",
          c: "0",
          n: "5",
          t: "੫"
        }, {
          i: "k6",
          c: "0",
          n: "6",
          t: "੬"
        }, {
          i: "k7",
          c: "0",
          n: "7",
          t: "੭"
        }, {
          i: "k8",
          c: "0",
          n: "8",
          t: "੮"
        }, {
          i: "k9",
          c: "0",
          n: "9",
          s: "(",
          t: "੯"
        }, {
          i: "k10",
          c: "0",
          n: "0",
          s: ")",
          t: "੦"
        }, {
          i: "k11",
          c: "0",
          n: "-"
        }, {
          i: "k12",
          c: "0",
          n: "="
        }, {
          i: "k13",
          c: "0",
          n: "ੌ",
          s: "ਔ"
        }, {
          i: "k14",
          c: "0",
          n: "ੈ",
          s: "ਐ"
        }, {
          i: "k15",
          c: "0",
          n: "ਾ",
          s: "ਆ"
        }, {
          i: "k16",
          c: "0",
          n: "ੀ",
          s: "ਈ"
        }, {
          i: "k17",
          c: "0",
          n: "ੂ",
          s: "ਊ"
        }, {
          i: "k18",
          c: "0",
          n: "ਬ",
          s: "ਭ"
        }, {
          i: "k19",
          c: "0",
          n: "ਹ",
          s: "ਙ"
        }, {
          i: "k20",
          c: "0",
          n: "ਗ",
          s: "ਘ",
          t: "ਗ਼"
        }, {
          i: "k21",
          c: "0",
          n: "ਦ",
          s: "ਧ"
        }, {
          i: "k22",
          c: "0",
          n: "ਜ",
          s: "ਝ",
          t: "ਜ਼"
        }, {
          i: "k23",
          c: "0",
          n: "ਡ",
          s: "ਢ",
          t: "ੜ"
        }, {
          i: "k24",
          c: "0",
          n: "਼",
          s: "ਞ"
        }, {
          i: "k25",
          c: "0",
          n: "\\"
        }, {
          i: "k26",
          c: "0",
          n: "ੋ",
          s: "ਓ"
        }, {
          i: "k27",
          c: "0",
          n: "ੇ",
          s: "ਏ"
        }, {
          i: "k28",
          c: "0",
          n: "੍",
          s: "ਅ"
        }, {
          i: "k29",
          c: "0",
          n: "ਿ",
          s: "ਇ"
        }, {
          i: "k30",
          c: "0",
          n: "ੁ",
          s: "ਉ"
        }, {
          i: "k31",
          c: "0",
          n: "ਪ",
          s: "ਫ",
          t: "ਫ਼"
        }, {
          i: "k32",
          c: "0",
          n: "ਰ"
        }, {
          i: "k33",
          c: "0",
          n: "ਕ",
          s: "ਖ",
          t: "ਖ਼"
        }, {
          i: "k34",
          c: "0",
          n: "ਤ",
          s: "ਥ"
        }, {
          i: "k35",
          c: "0",
          n: "ਚ",
          s: "ਛ"
        }, {
          i: "k36",
          c: "0",
          n: "ਟ",
          s: "ਠ"
        }, {
          i: "k37",
          c: "0"
        }, {
          i: "k38",
          c: "0",
          n: "ੰ",
          s: "ਂ"
        }, {
          i: "k39",
          c: "0",
          n: "ਮ",
          s: "ਣ"
        }, {
          i: "k40",
          c: "0",
          n: "ਨ"
        }, {
          i: "k41",
          c: "0",
          n: "ਵ",
          s: "ੲ",
          t: "ੳ"
        }, {
          i: "k42",
          c: "0",
          n: "ਲ",
          s: "ਲ਼"
        }, {
          i: "k43",
          c: "0",
          n: "ਸ",
          s: "ਸ਼"
        }, {
          i: "k44",
          c: "0",
          n: ","
        }, {
          i: "k45",
          c: "0",
          n: ".",
          s: "।",
          t: "॥"
        }, {
          i: "k46",
          c: "0",
          n: "ਯ"
        }, {
          i: "k47",
          c: "0"
        }],
        deadkeys: []
      }
    }, {
      Id: "phonetic",
      Name: "Phonetic Keyboard",
      Json: {
        name: "Punjabi Phonetic",
        dir: "ltr",
        keys: [{
          i: "k0",
          c: "0",
          n: "`",
          s: "~"
        }, {
          i: "k1",
          c: "0",
          n: "੧",
          s: "!"
        }, {
          i: "k2",
          c: "0",
          n: "੨",
          s: "@"
        }, {
          i: "k3",
          c: "0",
          n: "੩",
          s: "#"
        }, {
          i: "k4",
          c: "0",
          n: "੪",
          s: "$"
        }, {
          i: "k5",
          c: "0",
          n: "੫",
          s: "%"
        }, {
          i: "k6",
          c: "0",
          n: "੬",
          s: "^"
        }, {
          i: "k7",
          c: "0",
          n: "੭",
          s: "&"
        }, {
          i: "k8",
          c: "0",
          n: "੮",
          s: "*"
        }, {
          i: "k9",
          c: "0",
          n: "੯",
          s: "("
        }, {
          i: "k10",
          c: "0",
          n: "੦",
          s: ")"
        }, {
          i: "k11",
          c: "0",
          n: "-",
          s: "_"
        }, {
          i: "k12",
          c: "0",
          n: "=",
          s: "+"
        }, {
          i: "k13",
          c: "1",
          n: "ੲ",
          s: "ੳ"
        }, {
          i: "k14",
          c: "1",
          n: "ੱ",
          s: "ਥ"
        }, {
          i: "k15",
          c: "1",
          n: "ਐ",
          s: "ਏ"
        }, {
          i: "k16",
          c: "1",
          n: "ਰ",
          s: "ੜ"
        }, {
          i: "k17",
          c: "1",
          n: "ਤ",
          s: "ਟ"
        }, {
          i: "k18",
          c: "1",
          n: "ਯ",
          s: "ਁ"
        }, {
          i: "k19",
          c: "1",
          n: "ਉ",
          s: "ਊ"
        }, {
          i: "k20",
          c: "1",
          n: "ਇ",
          s: "ਈ"
        }, {
          i: "k21",
          c: "1",
          n: "ਓ",
          s: "ਔ"
        }, {
          i: "k22",
          c: "1",
          n: "ਪ",
          s: "ਫ",
          t: "ਫ਼"
        }, {
          i: "k23",
          c: "0",
          n: "।",
          s: "॥"
        }, {
          i: "k24",
          c: "0"
        }, {
          i: "k25",
          c: "0",
          n: "\\",
          s: "|"
        }, {
          i: "k26",
          c: "1",
          n: "ਅ",
          s: "ਆ"
        }, {
          i: "k27",
          c: "1",
          n: "ਸ",
          s: "ਠ",
          t: "ਸ਼"
        }, {
          i: "k28",
          c: "1",
          n: "ਦ",
          s: "ਧ"
        }, {
          i: "k29",
          c: "1",
          n: "ੰ",
          s: "ਂ"
        }, {
          i: "k30",
          c: "1",
          n: "ਗ",
          s: "ਘ",
          t: "ਗ਼"
        }, {
          i: "k31",
          c: "1",
          n: "ਹ",
          s: "ਃ"
        }, {
          i: "k32",
          c: "1",
          n: "ਜ",
          s: "ਝ",
          t: "ਜ਼"
        }, {
          i: "k33",
          c: "1",
          n: "ਕ",
          s: "ਖ",
          t: "ਖ਼"
        }, {
          i: "k34",
          c: "1",
          n: "ਲ",
          s: "੍",
          t: "ਲ਼"
        }, {
          i: "k35",
          c: "0",
          n: ";",
          s: ":"
        }, {
          i: "k36",
          c: "0",
          n: "'",
          s: '"'
        }, {
          i: "k37",
          c: "1",
          n: "ਡ",
          s: "ਢ"
        }, {
          i: "k38",
          c: "1",
          n: "਼",
          s: "ਞ"
        }, {
          i: "k39",
          c: "1",
          n: "ਚ",
          s: "ਛ"
        }, {
          i: "k40",
          c: "1",
          n: "ਵ",
          s: "ਙ"
        }, {
          i: "k41",
          c: "1",
          n: "ਬ",
          s: "ਭ"
        }, {
          i: "k42",
          c: "1",
          n: "ਨ",
          s: "ਣ"
        }, {
          i: "k43",
          c: "1",
          n: "ਮ",
          s: "ੴ"
        }, {
          i: "k44",
          c: "0",
          n: ",",
          s: "<"
        }, {
          i: "k45",
          c: "0",
          n: ".",
          s: ">"
        }, {
          i: "k46",
          c: "0",
          n: "/",
          s: "?"
        }, {
          i: "k47",
          c: "0",
          n: "\\",
          s: "|"
        }],
        deadkeys: []
      }
    }];
    if (k.length == 1) {
      g.loadVirtualLayout(k[0].Json)
    } else {
      var h = [];
      var l = [];
      if (!n.layout) {
        n.layout = k[0].Id
      }
      var m = false;
      for (var c = 0; c < k.length; c++) {
        h.push("<label", (c > 0 ? ' style="margin-left:2em"' : ""), '><input id="', k[c].Id, '" name=type type=radio', (n.layout == k[c].Id ? ' checked="checked"' : ""), "> ", k[c].Name, "</label>");
        l[c] = new a.layout();
        l[c].load(k[c].Json);
        if (n.layout == k[c].Id) {
          g.loadVirtualLayout(k[c].Json);
          m = true
        }
      }
      if (m == false) {
        g.loadVirtualLayout(k[0].Json)
      }
      var j = document.createElement("p");
      j.innerHTML = h.join("");
      document.getElementById("keyboard").appendChild(j);
      for (var c = 0; c < k.length; c++) {
        var r = function (e, i) {
          document.getElementById(e).onclick = function () {
            if (n.layout != e) {
              g.virtualLayout = i;
              g.currentLayout = g.virtualLayout;
              g.reset();
              g.drawKeyboard();
              g.textbox.style.direction = i.dir;
              n.layout = e;
              if (o && f) {
                localStorage.setItem(t, JSON.stringify(n))
              }
            }
            g.textbox.focus()
          }
        }(k[c].Id, l[c])
      }
    }
    a.util.indexOf = function (e, w) {
      var v = e.length;
      for (var u = 0; u < v; u++) {
        if (e[u] == w) {
          return u
        }
      }
      return -1
    }
      ;
    a.util.composeBrahmi = function (C, z, u, B) {
      var y = B.length;
      if (y == 0) {
        return ""
      }
      var x = B.charCodeAt(0);
      var A = String.fromCharCode(x);
      var e, w;
      for (var v = 1; v < y; ++v) {
        e = B.charCodeAt(v);
        w = a.util.indexOf(C, e);
        if (a.util.indexOf(u, x) != -1 && x == e) { }
        if (a.util.indexOf(u, x) != -1 && w != -1) {
          e = z[w]
        }
        if (x == 39 && w != -1) {
          A = A.slice(0, A.length - 1)
        }
        x = e;
        A = A + String.fromCharCode(e)
      }
      return A
    }
      ;
  }



  



//   return (
//     <div id="page">
            
//             <div class="main_content">
//                 <div class="side_content">
                   
//                 </div>
               
               
//                 <p>
//                     <textarea id="editor" name="editor" rows="" cols="75"></textarea>
//                 </p>
//                 <div id="keyboard"></div>
              
//             </div>
            
//             {/* <div id="gdpr">
                
//                 <button id="gdpr-btn" type="button">Accept</button>
//             </div> */}
//         </div>
//   )
// }


export default NewKeyboard