import { BrowserRouter as Router, Route, Outlet, Link, Routes, Navigate } from "react-router-dom";
import "./App.css"
import Login from "./auth/login"
import ForgetPassword from "./auth/forget-password"
import SignUp from "./auth/signup"
import HomeAdmin from "./pages/dashboard/admin"
import HomePublic from "./pages/dashboard/public"
import LeaderBoard from "./pages/dashboard/leaderboard"
import ServicesIndex from "./pages/services/services-front"
import SpeechRecognition from "./pages/contribution/speech-recognition/speech-recognition-page";
import ApiIndex from "./pages/api/api-front-page"
import { useSelector } from "react-redux"


import Examples from "./pages/examples"

import Contribution from "./pages/contribution/contribution-front";

import { Gurmukhi } from "./pages/gurmukhi/gurmukhi-page";
import { Fonts } from "./pages/fonts";
import Dictionary from "./pages/dictionary";
import AuthCopy from "./auth/shared/auth-copyright";
import Header from "./components/header";
import ServicesFront from "./pages/services/services-front";
import GurbaniFront from "./pages/contribution/gurbani/gurbani-page";
import Test from "./pages/contribution/test";
import Tagging from "./pages/contribution/tagging";
import Translate from "./pages/services/translate-page";
import Transliterate from "./pages/services/transliterate";
import NerPage from "./pages/services/ner-page";
import ImageTagging from "./pages/contribution/imagetagging";
import ViewCorpuses from "./pages/contribution/upload-corpus/viewCorpuses";
import UploadNew from "./pages/contribution/upload-corpus/uploadNew";

function App() {

  const Data = useSelector(state => state)

  return (
    <div className="App">
  
      <Router>
        
        <Routes>

          <Route element={<PrivateRoutes/>}>
            <Route path="/home-admin" element={<HomeAdmin />} />
            <Route path="/elements-uses" element={<Examples />} />
            <Route path="/leaderboard" element={<LeaderBoard />} />
            <Route path="/api" element={<ApiIndex />} />
            {/* <Route path="/services" element={<ServicesIndex />} />  */}
                 
            <Route path="/gurmukhi" element={<Gurmukhi />} />
            <Route path="/fonts" element={<Fonts />} />
            <Route path="/dictionary" element={<Dictionary />} />      
           
            <Route path="/contribution" element={<Contribution />} />
            <Route path="/speech-recognition" element={<SpeechRecognition />} />       
            
            <Route path="/view_corpuses" element={<ViewCorpuses/>} />    
            <Route path="/upload_new_corpus" element={<UploadNew/>} />    
            
            <Route path="/gurbani" element={<GurbaniFront/>} /> 

            <Route path="/services" element={<ServicesFront/>} />
            <Route path="/ner" element={<NerPage/>} /> 
            <Route path="/translation" element={<Translate/>} /> 
            <Route path="/transliteration" element={<Transliterate/>} /> 

            <Route path="/imagetagging" element={<ImageTagging/>} /> 
                    
          </Route>

          <Route path="/" element={<Login />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/signup" element={<SignUp />} />
          
          <Route path="/home-public" element={<HomePublic />} />
          <Route path="/test" element={<Test/>} />
          <Route path="/tagging" element={<Tagging/>} />
            

        </Routes>
      </Router>

    </div>
  )
}


const PrivateRoutes = () => {
  const Data = useSelector(state => state)
  return Data.auth.isSignedIn ? <Header/> : <Navigate to={'/'} />
}


export default App