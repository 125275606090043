import React , {useState} from 'react'
import { Link } from 'react-router-dom'
import '../shared/page.css'
import AuthCopy from '../../auth/shared/auth-copyright'
import ApiPop from './api-pop'
import { DocContent } from './doc-content'

function ApiIndex() {

    const [pop, setpop] = useState(false)
    const [active, setactive] = useState(1)

    function handleClick(e) {
        setpop(e.target.id)

    }

    return (
        <> {pop !=false ? <ApiPop pop={pop} setpop={setpop}/> : 
           <div className='pageWrap lightWhite withCopy'>
                
                <div className='grayBox p-5 xl:p-8'>
                    <div className='max-w-xl py-4 xl:py-8 mx-auto text-center'>
                        <h1 className='font-semibold text-2xl text-gradient mb-4'>Translate with Panjabi.AI API</h1>
                        <p className='mb-8 text-gray'>Integrate the best machine translation software for Punjabi into your own platforms and products.</p>
                        <hr className='my-4 text-gray' />
                        <p>Note: Learn more from <a target="_blank" className='underline hover:underline-offset-4' href="https://test.pypi.org/project/punjabi.AI/"><strong>python pakage</strong></a></p>
                        {/* <div className='text-center'>
                            <button type='button' className="px-4 btnPrimary text-white font-semibold py-2 d-inline-block mx-2">
                                Sign up for free
                            </button>
                            <button type='button' className="px-4 btnOutline text-primary font-semibold py-2 d-inline-block mx-2">
                                Read the Docs
                            </button>
                        </div> */}
                    </div>
                </div> 

                <div className='p-5 xl:py-12'>
                    <div className='max-w-6xl mx-auto'>
                        <h1 className='text-gray font-semibold text-2xl mb-5 lg:mb-10 text-center'>The all of your translation needs in one place</h1>
                        
                        <ul className='list-none m-0 p-0 flex gap-8 flex-wrap justify-center'>
                            <li className='linkBox min-w-[240px] relative rounded-xl bgLight p-4 lg:py-8 text-gray text-center'>
                                <span className='ico pu-trans'></span>
                                <div  className='pt-5 text-sm'>
                                    <Link id='translation' onClick={(e)=> handleClick(e)} to=''>Punjabi Translation</Link>
                                </div>
                            </li>
                            <li className='linkBox min-w-[240px] relative rounded-xl bgLight p-4 lg:py-8 text-gray text-center'>
                                <span className='ico machine-trans'></span>
                                <div className='pt-5 text-sm'>
                                    <Link id='machine transliteration' onClick={(e)=> handleClick(e)} to=''>Machine Transliteration</Link>
                                </div>
                            </li>
                            <li className='linkBox min-w-[240px] relative rounded-xl bgLight p-4 lg:py-8 text-gray text-center'>
                                <span className='ico doc'></span>
                                <div className='pt-5 text-sm'>
                                    <Link id='document translation' onClick={(e)=> handleClick(e)} to=''>Document Translation</Link>
                                </div>
                            </li>
                            <li className='linkBox min-w-[240px] relative rounded-xl bgLight p-4 lg:py-8 text-gray text-center'>
                                <span className='ico voice-recog'></span>
                                <div className='pt-5 text-sm'>
                                    <Link id='Named Entity Recognition' onClick={(e)=> handleClick(e)} to=''>Named Entity Recognition</Link>
                                </div>
                            </li>
                            <li className='linkBox min-w-[240px] relative rounded-xl bgLight p-4 lg:py-8 text-gray text-center'>
                                <span className='ico speech'></span>
                                <div className='pt-5 text-sm'>
                                    <Link id='pos' onClick={(e)=> handleClick(e)} to=''>Part of Speech</Link>
                                </div>
                            </li>
                            <li className='linkBox min-w-[240px] relative rounded-xl bgLight p-4 lg:py-8 text-gray text-center'>
                                <span className='ico upload'></span>
                                <div className='pt-5 text-sm'>
                                    <Link id='upload corpus' onClick={(e)=> handleClick(e)} to=''>Upload Corpus</Link>
                                </div>
                            </li>
                            <li className='linkBox min-w-[240px] relative rounded-xl bgLight p-4 lg:py-8 text-gray text-center'>
                                <span className='ico gurbani'></span>
                                <div className='pt-5 text-sm'>
                                    <Link id='gurbani' onClick={(e)=> handleClick(e)} to=''>Gurbani</Link>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div> 

                {/* <div className='flex'>
                    <div className='w-[15%]' >
                        <div onClick={()=>setactive(1)} className={`cursor-pointer bg-white text-black w-10 ${active==1 ? 'font-bold' : ''}`}>Punjabi Translation</div>
                        <div onClick={()=>setactive(2)} className={`cursor-pointer bg-white text-black w-10 ${active==2 ? 'font-bold' : ''}`}>Machine Transliteration</div>
                        <div onClick={()=>setactive(3)} className={`cursor-pointer bg-white text-black w-10 ${active==3 ? 'font-bold' : ''}`}>Named Entity Recognition</div>
                        <div onClick={()=>setactive(4)} className={`cursor-pointer bg-white text-black w-10 ${active==4 ? 'font-bold' : ''}`}>Part of Speech</div>
                    </div>
                    <div className='w-[85%]'>
                        {<DocContent active={active}/>}
                    </div>
                </div> */}

    
               

                <div className='copyFloat'><AuthCopy /></div>          
            </div>  }
        </>
    )
}

export default ApiIndex