import { useQuery , useMutation } from "react-query";
import axios from "axios";
import { useSelector } from "react-redux";
import { BACKEND_URL } from "../../../config";

export const useViewCorpuses = ()=> {

    const Data = useSelector(state => state)
    console.log(Data)
    return useQuery(
        {
          queryFn: async () => {
            
            // return await axios.get(BACKEND_URL + `/view_corpuses`,{
            //    headers: {  Authorization: "Bearer " + Data.auth["access_token"] }
            // }
            // ).then(res => res.json
            //  ).then(dataJson => setcorpaList(dataJson.data) )
            const response = await axios.request({
                headers: {
                  Authorization: `Bearer ${Data.auth["access_token"]}`
                },
                method: "GET",
                url: BACKEND_URL + `/view_corpuses`
              })
            console.log(response , "WE")
            return response.data ? response.data : []
          },
          onSuccess: (data) => {

          }
        }
      )
}

export const useUploadNewCorpus = () => {
    const Data = useSelector(state => state)


    return useMutation(
        {
          mutationFn: async (data) => {
            console.log(data , "DATAA")
            
            return await axios.post(BACKEND_URL + `/upload_corpus`, data , {
               headers: {  Authorization: "Bearer " + Data.auth["access_token"] }
            }
            )
          },
          onSuccess: (data) => {
              
          }
        }
      )
}