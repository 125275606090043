import React from 'react'

function ApiPop({ pop, setpop }) {
  let body = ""
  switch (pop) {
    case 'translation':
      body = JSON.stringify({ "text": "ਪਾਕਿਸਤਾਨ ਨੇ ਕਰਤਾਰਪੁਰ ਲਾਂਘੇ ਦੀ ਉਸਾਰੀ ਪੂਰੀ ਕਰਨ ਲਈ ਵਿਦੇਸ਼ਾਂ 'ਚ ਵੱਸਦੇ ਸਿੱਖਾਂ ਤੋਂ ਮੰਗੀ ਮਦਦ" })
      break;
    case 'machine transliteration':
      body = JSON.stringify({ "text": "ਪਾਕਿਸਤਾਨ ਨੇ ਕਰਤਾਰਪੁਰ ਲਾਂਘੇ ਦੀ ਉਸਾਰੀ ਪੂਰੀ ਕਰਨ ਲਈ ਵਿਦੇਸ਼ਾਂ 'ਚ ਵੱਸਦੇ ਸਿੱਖਾਂ ਤੋਂ ਮੰਗੀ ਮਦਦ" })
      break;
    case 'document translation':
      body = JSON.stringify({ "text": "ਪਾਕਿਸਤਾਨ ਨੇ ਕਰਤਾਰਪੁਰ ਲਾਂਘੇ ਦੀ ਉਸਾਰੀ ਪੂਰੀ ਕਰਨ ਲਈ ਵਿਦੇਸ਼ਾਂ 'ਚ ਵੱਸਦੇ ਸਿੱਖਾਂ ਤੋਂ ਮੰਗੀ ਮਦਦ" })
      break;
    case 'Named Entity Recognition':
      body = JSON.stringify({ "text": "ਪਾਕਿਸਤਾਨ ਨੇ ਕਰਤਾਰਪੁਰ ਲਾਂਘੇ ਦੀ ਉਸਾਰੀ ਪੂਰੀ ਕਰਨ ਲਈ ਵਿਦੇਸ਼ਾਂ 'ਚ ਵੱਸਦੇ ਸਿੱਖਾਂ ਤੋਂ ਮੰਗੀ ਮਦਦ" })
      break;

    case 'pos':
      body = JSON.stringify({ "text": "ਪਾਕਿਸਤਾਨ ਨੇ ਕਰਤਾਰਪੁਰ ਲਾਂਘੇ ਦੀ ਉਸਾਰੀ ਪੂਰੀ ਕਰਨ ਲਈ ਵਿਦੇਸ਼ਾਂ 'ਚ ਵੱਸਦੇ ਸਿੱਖਾਂ ਤੋਂ ਮੰਗੀ ਮਦਦ" })
      break;
    case 'upload corpus':
      body = JSON.stringify({ "text": "ਪਾਕਿਸਤਾਨ ਨੇ ਕਰਤਾਰਪੁਰ ਲਾਂਘੇ ਦੀ ਉਸਾਰੀ ਪੂਰੀ ਕਰਨ ਲਈ ਵਿਦੇਸ਼ਾਂ 'ਚ ਵੱਸਦੇ ਸਿੱਖਾਂ ਤੋਂ ਮੰਗੀ ਮਦਦ" })
      break;
    case 'gurbani':
      body = JSON.stringify({ "text": "ਪਾਕਿਸਤਾਨ ਨੇ ਕਰਤਾਰਪੁਰ ਲਾਂਘੇ ਦੀ ਉਸਾਰੀ ਪੂਰੀ ਕਰਨ ਲਈ ਵਿਦੇਸ਼ਾਂ 'ਚ ਵੱਸਦੇ ਸਿੱਖਾਂ ਤੋਂ ਮੰਗੀ ਮਦਦ" })
      break;
    default:
      break;
  }


  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
      >
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h4 className="text-3xl font-semibold text-primary">
                API  Integration
              </h4>
              <button
                className="popClose"
                onClick={() => setpop(false)}
              >
                <span className="bg-transparent text-white h-6 w-6 text-3xl block outline-none focus:outline-none">&times;</span>
              </button>
            </div>
            {/*body*/}
            <div className="popBody relative p-6 flex-auto">
              <div className='text-sm text-black'>
                <p>Request method : POST</p>
                <p> {"MAIN_URL: https://nlp-toolkit.panjabi.ai/<endpoint>"}</p>
                <p>choose endpoint from the table</p>
                <table className='border-collapse table-auto w-full my-6'>
                  <thead>
                    <tr>
                      <th>Endpoint</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pop == 'translation' ?
                      <tr className='border'>
                        <td className='border m-2 p-2'>pun2engtranslation</td>
                        <td className='border m-2 p-2'>Punjabi to English Machine Translation</td>
                      </tr> : null}
                    {pop == 'translation' ?
                      <tr className='border'>
                        <td className='border m-2 p-2'>eng2puntranslation</td>
                        <td className='border m-2 p-2'>English to Punjabi Machine Translation</td>
                      </tr> : null}
                    {pop == 'machine transliteration' ?
                      <tr className='border'>
                        <td className='border m-2 p-2'>pun2engtransliteration</td>
                        <td className='border m-2 p-2'>Punjabi to English Transliteration</td>
                      </tr> : null}
                    {pop == 'machine transliteration' ?
                      <tr className='border'>
                        <td className='border m-2 p-2'>eng2puntransliteration</td>
                        <td className='border m-2 p-2'>English to Punjabi Transliteration</td>
                      </tr> : null}
                    {pop == 'Named Entity Recognition' ?
                      <tr className='border'>
                        <td className='border m-2 p-2'>ner_allennlp</td>
                        <td className='border m-2 p-2'>NER computed using AllenNLP</td>
                      </tr> : null}
                    {pop == 'Named Entity Recognition' ?
                      <tr className='border'>
                        <td className='border m-2 p-2'>ner_ktrain</td>
                        <td className='border m-2 p-2'>NER computed using Ktrain</td>
                      </tr> : null}
                    {pop == 'pos' ?
                      <tr className='border'>
                        <td className='border m-2 p-2'>pos_rule_based</td>
                        <td className='border m-2 p-2'>PoS computed using rule based approach</td>
                      </tr> : null}

                    {pop == 'pos' ?
                      <tr className='border'>
                        <td className='border m-2 p-2'>pos</td>
                        <td className='border m-2 p-2'>PoS computed using deep learning method</td>
                      </tr> : null}

                  </tbody>

                </table>


                <p>body : {body}</p>
                {/* <p>you will get a reqponse like this:</p> */}

              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="opacity-75 fixed inset-0 z-40 bg-black"></div>
    </>
  )
}

export default ApiPop