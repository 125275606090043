import React from 'react'
import { Link } from "react-router-dom"

function SideBox() {
    return (
        <>
            <div className='h-96 md:h-screen'>
                <div className='sideAuthBox'>
                    <div className='captionBox w-full md:w-96'>
                        <h1 className='uppercase text-2xl md:text-3xl mb-2'>
                            <Link to="/">PANJABI.AI</Link>
                        </h1>
                        <p className='m-0'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting,</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SideBox