import React from 'react'
import { Link, useParams } from "react-router-dom"
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

function NavItems() {
    const [active, setactive] = useState('dsahboard')
    const Data = useSelector(item => item)
    let location = useLocation()

    
    return (
        <>
            <ul className="nav items-center justify-center lg:flex">
                <li className={`text-white hover:text-indigo-200 ${['/home-public' , '/home-admin' , '/leaderboard'].includes(location.pathname) ? 'active' : ''}`}>
                    <Link id='dashboard' to='/home-admin'>Dashboard</Link>
                </li>
                <li className={`text-white hover:text-indigo-200 ${['/services' , '/translate' , '/transliteration' ].includes(location.pathname) ? 'active' : ''}`}>
                    <Link  id='services' to='/services'>Our Services</Link>                
                </li>
                <li className={`text-white hover:text-indigo-200 ${location.pathname == '/contribution' ? 'active' : ''}`}>
                    <Link id='contribution' to='/contribution'>Contribution</Link>
                </li>
                <li className={`text-white hover:text-indigo-200 ${location.pathname == '/api' ? 'active' : ''}`}>
                    <Link id='api' to='/api'>API</Link>
                </li>
                <li className={`text-white hover:text-indigo-200 ${location.pathname == '/gurmukhi' ? 'active' : ''}`}>
                    <Link id='gurmukhi' to='/gurmukhi'>Gurmukhi Alphabets</Link>
                </li>
                {/* <li className={`text-white hover:text-indigo-200 ${location.pathname == '/fonts' ? 'active' : ''}`}>
                    <Link id='fonts' to='/fonts'>Panjabi Fonts</Link>
                </li> */}
                <li className={`text-white hover:text-indigo-200 ${location.pathname == '/dictionary' ? 'active' : ''}`}>
                    <Link id='dictionary' to='/dictionary'>Dictionary</Link>
                </li>
            </ul>
        </>
    )
}

export default NavItems