import React, { useState , useEffect, useRef } from 'react'
import styles from '../shared/common.module.css'
import pfont from '../shared/pfont.module.css'
import KeyboardAltOutlinedIcon from '@mui/icons-material/KeyboardAltOutlined'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import ShareIcon from '@mui/icons-material/Share'
import HistoryIcon from '@mui/icons-material/History'
import VolumeUpIcon from '@mui/icons-material/VolumeUp'
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined'
import AuthCopy from '../../auth/shared/auth-copyright'
import KeyBoard from '../../components/virtual-keyboard'
import Select from './select'
import SelectLang from './selectLang'
import { useMutation } from 'react-query'
import axios from 'axios'
import { useLocation } from 'react-router-dom'
import NewKeyboard from '../../components/newKeyboard'
import {Codes , repeatCodes} from "../../components/keyMap"
import { NER_TAGS } from '../../config'
import { useGetResult } from './hooks'

function NerPage() {
  const location = useLocation()
  const [services, setservices] = useState(location.pathname.split('/')[1])
  const [isOpened, setIsOpened] = useState(true);
  const [isToggled, setIsToggled] = useState(false);
  const [lang, setlang] = useState('punjabi')
  const [endpoint, setendpoint] = useState('ner_ktrain')

  const [inpText, setinpText] = useState("")
  const [outputText, setoutputText] = useState("")
  const [Tags, setTags] = useState(NER_TAGS)
  const [Output, setOutput] = useState(null)
  const getresult_hook = useGetResult()



  function toggleKB() {
    setIsOpened(wasOpened => !wasOpened);
  }

  function toggleExpandB() {
    setIsToggled(wasToggled => !wasToggled);
  }


  function handleChange(e) {
    
    if (lang === 'punjabi' && e.nativeEvent.inputType === 'insertText' ) {
      if (inpText[inpText.length-1] == Codes[e.nativeEvent.data] ){
        if ( repeatCodes[e.nativeEvent.data] !== inpText[inpText.length-1]){
          setinpText(p => p.substring(0,p.length) + Codes[e.nativeEvent.data])
        }
      }
      else{
        setinpText(p => p.substring(0,p.length) + Codes[e.nativeEvent.data])
      }
     
    }
    else{
      setinpText(e.target.value)
    }
    

  }


  useEffect(() => {
    if(getresult_hook.isSuccess) {
   
      setOutput(getresult_hook.data.data[endpoint])
    }
  }, [getresult_hook])
  
  

  return (
    <>
    <div className='px-3 lg:px-5'>
        <div className='grayBox rounded-xl p-4 mb-5 flex gap-2'>
                        <Select services={services}/>
                        <SelectLang lang={lang} setlang={setlang} services={services} setendpoint={setendpoint}/>
        </div> 

      
        

          <div className={`${styles.transBox} mb-3 ${lang != 'punjabi' ? 'h-[550px]' : 'h-700'}`}>
            <div className='block lg:flex h-full'>
              <div className='lg:w-2/3 h-1/2 lg:h-full overflow-hidden'>
                <div className={`fstBox ${isOpened ? 'h-[200px] lg:h-[444px]' : 'h-full'} ${lang != 'punjabi' ? 'lg:h-[700px]' : ''}`}>
                  <div className={`h-full ${styles.textAreaPne}`}>
                    <div className='flex h-full p-4 overflow-auto'>
                      {/* Index */}
                      <div className='flex-none opacity-40 leading-relaxed w-[2rem]'>
                        <div>1</div>
                        <div>&nbsp;</div>
                        <div>3</div>
                        <div>4</div>
                      </div>
                      <div className='flex-1'>
                        <textarea id="editor" className='p-0' value={inpText} onChange={handleChange}  placeholder='Add the text here'></textarea>
                      </div>
                    </div>                    
                    {lang =='punjabi' ? 
                    <div className={`flex justify-between p-3 ${styles.btnHldr}`}>
                    
                      <div>
                        <button className={styles.kbtoggleBtn} onClick={toggleKB} title='Toggle Keyboard'><KeyboardAltOutlinedIcon /></button>
                      </div> 
                      <div>
                        <button onClick={()=> getresult_hook.mutate({inpText , endpoint})} className='px-4 btnPrimary text-white font-semibold py-2 lg:d-inline-block'>{getresult_hook.isLoading ? 'loading...' : 'Generate Result' }</button>
                      </div>
                    </div> : 
                    <div className={styles.nonPunjabiBtnHldr}>
                    <button onClick={()=> getresult_hook.mutate({inpText , endpoint})} className='px-4 btnPrimary text-white font-semibold py-2 lg:d-inline-block'>{getresult_hook.isLoading ? 'loading...' : 'Generate Result' }</button>
                  </div> }

                  </div>
                </div>
                
                  
                  {lang =='punjabi' ? 
                  <div className={styles.scndKBBox}>
                    
                    <KeyBoard/>
                  </div> : 
                  null}
                
               
              </div>
              <div className={`lg:w-1/3 h-1/2 lg:h-full ${styles.rightSBox}`}>
                <div className={`h-full relative ${styles.textAreaPne}`}>

                  <textarea className='absolute' value={outputText} placeholder={getresult_hook.isLoading ? 'Loading...' : ""}></textarea>
                  <div className='p-3 h-full w-full top-0 left-0 absolute '>
                    {Output && Output.map((itm)=> {
                      if (itm[1]=='O') {
                        return <div className='bbox inline-block p-[2px] '>
                          {itm[0]}
                          
                        </div>

                      }
                      else{

                        return <div className='hbbox inline-block p-[2px] '>
                          {itm[0]} {itm[1] !=='O' ? 
                          <select value={itm[1].split('-')[1]} className='selectTag '>
                            {Tags.map((x) => {
                              return (
                                <option value={x}>{x}</option>
                              )
                            })}
                            
                          </select> :
                           '' } 
                          {/* <button className='clos-b'>&times;</button> */}
                          </div>
                      }

                    })}
                   
                  </div>

                  
                  {/* <textarea value={outputText} placeholder={mutation.isLoading ? 'Loading...' : ""}></textarea> */}
                  <div className={`flex justify-end px-3 pb-3 ${styles.btnHldr}`}>
                    <div className='px-1'><button className={styles.optActBtn} title='Volume'><VolumeUpIcon /></button></div>
                    <div className='px-1'><button className={styles.optActBtn} title='History'><HistoryIcon /></button></div>
                    <div className='px-1'><button className={styles.optActBtn} title='Share'><ShareIcon /></button></div>
                    <div className='px-1'><button className={styles.optActBtn} title='Copy'><ContentCopyIcon /></button></div>
                    <div className='px-1'><button className={styles.optActBtn} title='Download'><FileDownloadIcon /></button></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='grayBox rounded-xl p-4 mb-3'>
            {/* Title Bar */}
            <div className='flex justify-between items-center'>
              <div><HistoryIcon /> <span className='inline-block pl-2'>History</span></div>
              <div>
                <button className={styles.optActBtn} onClick={toggleExpandB} title='Expand'><ExpandCircleDownOutlinedIcon /></button>
              </div>
            </div>
            {isToggled && (
              <div className='pt-3'>
                <ul className='list-disc list-outside pl-4'>
                  <li>Lorem Ipsum dolor sit amet</li>
                  <li>Lorem Ipsum dolor sit amet</li>
                </ul>
              </div>
            )}
          </div>
      </div>
         
    </>
  )
}

export default NerPage