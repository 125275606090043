import React from 'react'
import styles from '../../shared/common.module.css'
import RecognitionFilter from './recognition-filter'
import StatusTable from './status-table'
import SpeechTextPane from './speech-textpane'
import SpeechsActions from './speech-actions'
import AuthCopy from '../../../auth/shared/auth-copyright'
import Select from '../../services/select'


function SpeechRecognition() {

    return (
        <>
            <div className='pageWrap lightWhite withCopy'>


                <div className={`${styles.pagecontainer} ${styles.pxC}`}>
                    <div className='grayBox rounded-xl p-4 mb-5'>
                        <div className='flex justify-between items-center'>
                            <div><Select /></div>
                            <div>
                                <button type='button' className={`${styles.blkBdrIcoBtn} ${styles.edit}`}>Suggest Edits</button>
                            </div>
                        </div>
                    </div>

                    <div className='block lg:flex lg:gap-4'>
                        <div className='w-full lg:w-1/3 mb-4 lg:mb-0'>
                            <div className='grayBox rounded-xl'>
                                <RecognitionFilter />
                                <StatusTable />
                            </div>
                        </div>
                        <div className='w-full lg:w-2/3'>
                            <div className='grayBox rounded-xl mb-4'>
                                <SpeechTextPane />
                            </div>   
                            <SpeechsActions />                         
                        </div>
                    </div>
                </div>

                <div className='copyFloat'><AuthCopy /></div>
            </div>
        </>
    )
}

export default SpeechRecognition