import React , {useState , useEffect} from 'react'
import styles from '../../shared/common.module.css'
import AuthCopy from '../../../auth/shared/auth-copyright'
import Select from '../../services/select'
import DragDrop from './dropfiles'
import CorpusContent from './corpus-content'
import CorpusListing from './corpus-listing'
import { useMutation, useQuery } from 'react-query'
import { BACKEND_URL } from '../../../config'
import axios from 'axios'
import { useFormik } from 'formik';
import { UploadCorpusSchema } from '../../../auth/authSchema'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux'
import LoaderForAll from '../../../components/loader-for-all'
import { useViewCorpuses } from './hooks'


function ViewCorpuses() {

    const Data = useSelector(state => state)
    console.log(Data , "DATA")
    const [newupload, setnewupload] = useState(false)

    const [file, setFile] = useState(null);
    const [viewcorpus, setviewcorpus] = useState(false)
    
    const formik = useFormik({
        initialValues: {
            name: "",
            description: "",
            source: "",
            category: "",
            sub_category: ""
        },
        validationSchema: UploadCorpusSchema,
        onSubmit: (values) => {
            if (! file){
                return
            }
            console.log(file)
            var fd = new FormData()
            fd.append("name" , values.name)
            fd.append("category" , values.category)
            fd.append("sub_category" , values.sub_category)
            fd.append("source" , values.source)
            fd.append("description" , values.description)
            fd.append("file" , file)

            view_corpuses_hook.mutate(fd)
        }
    });



      const [corpaList, setcorpaList] = useState([])
      const view_corpuses_hook = useViewCorpuses()


      useEffect(() => {
        console.log("first")
        if (view_corpuses_hook.isSuccess) { 
            console.log(view_corpuses_hook)
            setcorpaList(view_corpuses_hook.data.data)
           
            
        }
        else if (view_corpuses_hook.isError) { 
            toast.error("Error is Fetching Data")
        }
      }, [view_corpuses_hook])
      




    return (
        <>
        {view_corpuses_hook.isLoading ?<LoaderForAll/> : 
            <div className='pageWrap lightWhite withCopy'>


                <div className={`${styles.pagecontainer} ${styles.pxC}`}>
                    

                   

                   

                    <CorpusListing corpaList={corpaList} view_corpuses_hook={view_corpuses_hook}/> 
                    
                </div>

                <div className='copyFloat'><AuthCopy /></div>
            </div> }
            <ToastContainer theme="colored" />
        </>
    )
}

export default ViewCorpuses