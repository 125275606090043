import React from 'react'
import styles from '../../shared/common.module.css'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { grey } from '@mui/material/colors'


function CorpusContent({formik}) {

    return (
        <>
            <div className='grayBox rounded-xl mb-5'>
                <h2 className={styles.contTitle}>Content Details</h2>
                <div className='p-4 lg:p-6'>
                    <form className={`${styles.bdrFields} block max-w-screen-md mx-auto`}>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-10 mb-4'>
                            <div>
                                <label className='block mb-2'>Name</label>
                                <input name='name' id="name" value={formik.values.name} onChange={formik.handleChange} type='text' placeholder='Enter here' required />
                            </div>
                           
                        </div>
                        <div className='grid grid-cols-1 md:grid-cols-3 gap-4 lg:gap-10 mb-4'>
                            <div>
                                <label className='block mb-2'>Source of Data</label>
                                <input name='source' id="source" value={formik.values.source} onChange={formik.handleChange} type='text' placeholder='Enter here' required />
                            </div>

                            <div>
                                <label className='block mb-2'>Category of Data</label>
                                <input name='category' id="category" value={formik.values.category} onChange={formik.handleChange} type='text' placeholder='Enter here' required />
                            </div>

                            <div>
                                <label className='block mb-2'>Sub Category of Data</label>
                                <input name='sub_category' id="sub_category" value={formik.values.sub_category} onChange={formik.handleChange} type='text' placeholder='Enter here' required />
                            </div>

                        </div>
                        <div className='grid grid-cols-1 gap-4 lg:gap-10 mb-4'>
                            <div>
                                <label className='block mb-2'>Description of Data</label>
                                <input name='description' id="description" value={formik.values.description} onChange={formik.handleChange} type='text' placeholder='Enter here' required />
                            </div>
                        </div>
                        {/* <div className='mb-5'>
                            <FormControlLabel required control={<Checkbox sx={{
                                color: grey[400],
                                '&.Mui-checked': {
                                    color: grey[200],
                                },
                            }} />} label="The content shared above is licensed." />
                        </div> */}
                        <div>
                            <button onClick={formik.handleSubmit} className='btnPrimary text-white font-semibold py-3 px-6'>
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default CorpusContent