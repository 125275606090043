import React , {useState , useEffect} from 'react'
import styles from '../../shared/common.module.css'
import AuthCopy from '../../../auth/shared/auth-copyright'
import Select from '../../services/select'
import DragDrop from './dropfiles'
import CorpusContent from './corpus-content'
import CorpusListing from './corpus-listing'
import { useMutation, useQuery } from 'react-query'
import { BACKEND_URL } from '../../../config'
import axios from 'axios'
import { useFormik } from 'formik';
import { UploadCorpusSchema } from '../../../auth/authSchema'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux'
import LoaderForAll from '../../../components/loader-for-all'
import { useUploadNewCorpus, useViewCorpuses } from './hooks'
import { useNavigate } from 'react-router-dom'


function UploadNew() {

    const Data = useSelector(state => state)
    const [newupload, setnewupload] = useState(false)

    const [file, setFile] = useState(null);
    const [viewcorpus, setviewcorpus] = useState(false)
    const upload_corpus_hook = useUploadNewCorpus()
    const navigate = useNavigate()
    
    const formik = useFormik({
        initialValues: {
            name: "",
            description: "",
            source: "",
            category: "",
            sub_category: ""
        },
        validationSchema: UploadCorpusSchema,
        onSubmit: (values) => {
            if (! file){
                return
            }
            console.log(file)
            var fd = new FormData()
            fd.append("name" , values.name)
            fd.append("category" , values.category)
            fd.append("sub_category" , values.sub_category)
            fd.append("source" , values.source)
            fd.append("description" , values.description)
            fd.append("file" , file)

            upload_corpus_hook.mutate(fd)
        }
    });


    useEffect(() => {
      if (upload_corpus_hook.isSuccess) {
        toast.success("Uploaded")
        navigate('/view_corpuses')
      }
      else if (upload_corpus_hook.isError) { 
        toast.error("Upload Failed")
      }
    }, [upload_corpus_hook])
    





    return (
        <>

{ upload_corpus_hook.isLoading ? <LoaderForAll/> :
<>
<div className='grayBox rounded-xl mb-4'>                        
    <div className='flex items-center justify-center h-[360px] lg:h-[480px]'>
        <DragDrop file={file} setFile={setFile} />
    </div>                        
</div>
<CorpusContent formik={formik} />
</> }


            <ToastContainer theme="colored" />
        </>
    )
}

export default UploadNew