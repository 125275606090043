import React from 'react'
import Header from '../../components/header'
import DashCounter from './dash-shared/counter'
import TopLeaders from './dash-shared/topleaders'
import CategoriesChart from './dash-shared/categories-linechart'
import TopCatList from './dash-shared/topcat-list'
import '../shared/page.css'

function HomePublic() {
    return (
        <>
            <div className='pageWrap'>
                <Header />
                <div className='dashTopPanel pt-3'>
                    <div className='block'>
                        <DashCounter />
                    </div>
                </div>
                <TopLeaders />
                <div className='block lg:flex'>
                    <div className='lg:w-2/3 bgLight'>
                        <CategoriesChart />
                    </div>
                    <div className='lg:w-1/3'>
                        <TopCatList />
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomePublic