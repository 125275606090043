import React, { useState , useEffect, useRef } from 'react'
import styles from '../shared/common.module.css'
import pfont from '../shared/pfont.module.css'
import KeyboardAltOutlinedIcon from '@mui/icons-material/KeyboardAltOutlined'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import ShareIcon from '@mui/icons-material/Share'
import HistoryIcon from '@mui/icons-material/History'
import VolumeUpIcon from '@mui/icons-material/VolumeUp'
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined'
import AuthCopy from '../../auth/shared/auth-copyright'
import KeyBoard from '../../components/virtual-keyboard'
import Select from './select'
import SelectLang from './selectLang'
import { useMutation } from 'react-query'
import axios from 'axios'
import { useLocation } from 'react-router-dom'
import NewKeyboard from '../../components/newKeyboard'
import {Codes , repeatCodes} from "../../components/keyMap"
import { useGetResult } from './hooks'

function Translate() {
  const location = useLocation()
  console.log(location , "LOC")
  const [services, setservices] = useState(location.pathname.split('/')[1])
  const [isOpened, setIsOpened] = useState(true);
  const [isToggled, setIsToggled] = useState(false);
  const [lang, setlang] = useState('punjabi')
  const [endpoint, setendpoint] = useState('pun2engtranslation')
  const [inpText, setinpText] = useState("")
  const [outputText, setoutputText] = useState("")
  const getresult_hook = useGetResult()

  const mutation = useMutation(
    {
      mutationFn: async () => {
        setoutputText("")
        return await axios.post(`https://nlp-toolkit.panjabi.ai/${endpoint}`, {
          "text": inpText
        }, {
          'Content-Type': 'application/json',
        })
      },
      onSuccess: (data) => {
          setoutputText(data?.data[endpoint])
      }


    }
  )




  function toggleKB() {
    setIsOpened(wasOpened => !wasOpened);
  }

  function toggleExpandB() {
    setIsToggled(wasToggled => !wasToggled);
  }

  function handleChange(e) {
    if (lang === 'punjabi' && e.nativeEvent.inputType === 'insertText' ) {
      var pos = e.target.selectionStart ; 
      var endPos = e.target.selectionEnd;
      if (inpText[pos-1] === Codes[e.nativeEvent.data] ){
        if ( repeatCodes[e.nativeEvent.data] !== inpText[inpText.length-1]){
          setinpText(p => p.slice(0,pos-1) + Codes[e.nativeEvent.data] + p.slice(endPos-1 , p.length))
        }
      }
      else{
        setinpText(p => p.slice(0,pos-1) + Codes[e.nativeEvent.data] + p.slice(endPos-1 , p.length))
      }
     
      setTimeout(() => {
        document.getElementById("editor").setSelectionRange(pos, pos);
      });

     
    }
    else{
      setinpText(e.target.value)
    }
    

  }

  useEffect(() => {
    if(getresult_hook.isSuccess) {
      setoutputText(getresult_hook.data.data[endpoint])
    }
  }, [getresult_hook])



  

  return (
    <>
    <div className='px-3 lg:px-5'>
    <div className='grayBox rounded-xl p-4 mb-5 flex gap-2'>
                        <Select services={services}/>
                        <SelectLang lang={lang} setlang={setlang} services={services} setendpoint={setendpoint}/>
                    </div> 

      
        

          <div className={`${styles.transBox} mb-3 ${lang != 'punjabi' ? 'h-[550px]' : 'h-700'}`}>
            <div className='block lg:flex h-full'>
              <div className='lg:w-2/3 h-1/2 lg:h-full overflow-hidden'>
                <div className={`fstBox ${isOpened ? 'h-[200px] lg:h-[444px]' : 'h-full'} ${lang != 'punjabi' ? 'lg:h-[700px]' : ''}`}>
                  <div className={`h-full ${styles.textAreaPne}`}>
                   
                   
                    <div className='flex h-full p-4 overflow-auto'>
                      {/* Index */}
                      {/* <div className='flex-none opacity-40 leading-relaxed w-[2rem]'>
                        <div>1</div>
                        <div>&nbsp;</div>
                        <div>3</div>
                        <div>4</div>
                      </div> */}
                      <div className='flex-1 relative'>
                        <textarea id="editor" className={`p-0 absolute ${lang === 'punjabi' ? pfont.panjabiFont : ''}`} value={inpText} onChange={handleChange}  placeholder='Add the text here'></textarea>
                        
                        {/* <div id="cursor-position" style={{ position: 'absolute', border: '1px solid green', backgroundColor: 'green' }}></div> */}
        
                        {/* {lables.length !==0 && lables.map((itm , ind) => {
                           return <div style={{marginLeft:`${itm.Leftmargin}px` ,  border:'1px solid red' , width: `${itm.width}px`, height: '18px' , position: 'absolute' , display: `${hidden ? 'none' : 'inline'}` }}></div>
                            
                          })} */}
                        
                      </div>
                    </div>                    
                    {lang =='punjabi' ? 
                    <div className={`flex justify-between p-3 ${styles.btnHldr}`}>
                    
                      <div>
                        <button className={styles.kbtoggleBtn} onClick={toggleKB} title='Toggle Keyboard'><KeyboardAltOutlinedIcon /></button>
                      </div> 
                      <div>
                        <button onClick={()=> getresult_hook.mutate({inpText , endpoint})} className='px-4 btnPrimary text-white font-semibold py-2 lg:d-inline-block'>{getresult_hook.isLoading ? 'loading...' : 'Translate' }</button>
                      </div>
                    </div> : 
                    <div className={styles.nonPunjabiBtnHldr}>
                    <button onClick={()=> getresult_hook.mutate({inpText , endpoint})} className='px-4 btnPrimary text-white font-semibold py-2 lg:d-inline-block'>{getresult_hook.isLoading ? 'loading...' : 'Translate' }</button>
                  </div> }

                  </div>
                </div>
                
                  
                  {lang =='punjabi' ? 
                  <div className={styles.scndKBBox}>
                    
                    <KeyBoard/>
                  </div> : 
                  null}
                
               
              </div>
              <div className={`lg:w-1/3 h-1/2 lg:h-full ${styles.rightSBox}`}>
                <div className={`h-full ${styles.textAreaPne}`}>
                  <textarea value={outputText} className={lang == 'english' ? pfont.panjabiFont : ''} placeholder={getresult_hook.isLoading ? 'Loading...' : ""}></textarea>
                  <div className={`flex justify-end px-3 pb-3 ${styles.btnHldr}`}>
                    <div className='px-1'><button className={styles.optActBtn} title='Volume'><VolumeUpIcon /></button></div>
                    <div className='px-1'><button className={styles.optActBtn} title='History'><HistoryIcon /></button></div>
                    <div className='px-1'><button className={styles.optActBtn} title='Share'><ShareIcon /></button></div>
                    <div className='px-1'><button className={styles.optActBtn} title='Copy'><ContentCopyIcon /></button></div>
                    <div className='px-1'><button className={styles.optActBtn} title='Download'><FileDownloadIcon /></button></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='grayBox rounded-xl p-4 mb-3'>
            {/* Title Bar */}
            <div className='flex justify-between items-center'>
              <div><HistoryIcon /> <span className='inline-block pl-2'>History</span></div>
              <div>
                <button className={styles.optActBtn} onClick={toggleExpandB} title='Expand'><ExpandCircleDownOutlinedIcon /></button>
              </div>
            </div>
            {isToggled && (
              <div className='pt-3'>
                <ul className='list-disc list-outside pl-4'>
                  <li>Lorem Ipsum dolor sit amet</li>
                  <li>Lorem Ipsum dolor sit amet</li>
                </ul>
              </div>
            )}
          </div>

         </div>
    </>
  )
}

export default Translate