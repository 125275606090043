import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ProgressBar from 'react-percent-bar'
import '../shared/page.css'

function LeaderBoard() {
    const navigate = useNavigate();

    return (
        <>
            <div className='pageWrap'>

                <div className='pageContWrap px-5 pt-3'>
                    <button type="button" className='backTxtBtn' onClick={() => navigate('/home-admin')}>Back</button>
                    <div className='tableListWrap'>
                        <div className='contTable min-w-[1200px]'>
                            <div className='tableHead flex items-center py-2'>
                                <div className='flex-none text-start px-3'>Rank</div>
                                <div className='flex-1 px-3'>Name</div>
                                <div className='flex-1 px-3'>Progress</div>
                                <div className='flex-1 px-3'>Time Spent</div>
                                <div className='flex-1 px-3'>Results</div>
                            </div>
                            <div className='tableBody'>
                                {/* Table Row Loop */}
                                <div className='tBodyRow flex py-1 rounded-md'>
                                    <div className='flex-none flex items-center text-start px-3'>
                                        <span className='badge'>1</span>
                                    </div>
                                    <div className='flex-1 items-center px-3 leading-3'>
                                        <div className='inline-flex h-full items-center px-4 text-left'>
                                            <div className='flex-none w-3rem'>
                                                <span className='dp' style={{ backgroundImage: `url('/profile/dp-01.jpg')` }}></span>
                                            </div>
                                            <div className='flex-auto pl-3 text-left leading-normal'>
                                                <p><strong>Jaskeeran Singh</strong></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex-1 px-3'>
                                        <div className='h-full flex justify-center items-center'>
                                            <div className='inline-block pr-3'>
                                                <ProgressBar borderColor="#585858" percent="85" fillColor="#44A0DF" width="120px" height="6px" />
                                            </div>
                                            <strong className='text-primary'>85%</strong>
                                        </div>
                                    </div>
                                    <div className='flex-1 px-3'>
                                        <div className='h-full flex items-center justify-center'>
                                            1hr 27min
                                        </div>
                                    </div>
                                    <div className='flex-1 px-3'>
                                        <div className='h-full flex items-center justify-center'>
                                            <Link to="">View Results</Link>
                                        </div>
                                    </div>
                                </div>
                                {/* Table Row Loop */}
                                <div className='tBodyRow flex py-1 rounded-md'>
                                    <div className='flex-none flex items-center text-start px-3'>
                                        <span className='badge'>2</span>
                                    </div>
                                    <div className='flex-1 items-center px-3 leading-3'>
                                        <div className='inline-flex h-full items-center px-4 text-left'>
                                            <div className='flex-none w-3rem'>
                                                <span className='dp' style={{ backgroundImage: `url('/profile/dp-01.jpg')` }}></span>
                                            </div>
                                            <div className='flex-auto pl-3 text-left leading-normal'>
                                                <p><strong>Jaskeeran Singh</strong></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex-1 px-3'>
                                        <div className='h-full flex justify-center items-center'>
                                            <div className='inline-block pr-3'>
                                                <ProgressBar borderColor="#585858" percent="85" fillColor="#44A0DF" width="120px" height="6px" />
                                            </div>
                                            <strong className='text-primary'>85%</strong>
                                        </div>
                                    </div>
                                    <div className='flex-1 px-3'>
                                        <div className='h-full flex items-center justify-center'>
                                            1hr 27min
                                        </div>
                                    </div>
                                    <div className='flex-1 px-3'>
                                        <div className='h-full flex items-center justify-center'>
                                            <Link to="">View Results</Link>
                                        </div>
                                    </div>
                                </div>
                                {/* Table Row Loop */}
                                <div className='tBodyRow flex py-1 rounded-md'>
                                    <div className='flex-none flex items-center text-start px-3'>
                                        <span className='badge'>3</span>
                                    </div>
                                    <div className='flex-1 items-center px-3 leading-3'>
                                        <div className='inline-flex h-full items-center px-4 text-left'>
                                            <div className='flex-none w-3rem'>
                                                <span className='dp' style={{ backgroundImage: `url('/profile/dp-01.jpg')` }}></span>
                                            </div>
                                            <div className='flex-auto pl-3 text-left leading-normal'>
                                                <p><strong>Jaskeeran Singh</strong></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex-1 px-3'>
                                        <div className='h-full flex justify-center items-center'>
                                            <div className='inline-block pr-3'>
                                                <ProgressBar borderColor="#585858" percent="85" fillColor="#44A0DF" width="120px" height="6px" />
                                            </div>
                                            <strong className='text-primary'>85%</strong>
                                        </div>
                                    </div>
                                    <div className='flex-1 px-3'>
                                        <div className='h-full flex items-center justify-center'>
                                            1hr 27min
                                        </div>
                                    </div>
                                    <div className='flex-1 px-3'>
                                        <div className='h-full flex items-center justify-center'>
                                            <Link to="">View Results</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
        </>
    )
}

export default LeaderBoard