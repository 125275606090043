import * as React from 'react'
import styles from '../shared/common.module.css'
import ClickAwayListener from '@mui/base/ClickAwayListener'
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { useNavigate } from 'react-router-dom'

function Select({services}) {
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate()

    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className={styles.dropbox}>
                <button type="button" onClick={handleClick}>
                    Select services<ArrowDropDownOutlinedIcon />
                </button>
                {open ? (
                    <div className={styles.dropped}>
                        <div className={styles.ddItem}>
                            <input checked={services==='ner'} onChange={()=>navigate('/ner')} type='radio' name="selectname" />
                            <label>Named Entity Recognition</label>
                            <CheckCircleOutlineIcon className={styles.nonChk} /> <CheckCircleIcon className={styles.chkd} />
                        </div>
                        {/* <div className={styles.ddItem}>
                            <input checked={services=='speech'} onChange={()=>navigate('/speech')} type='radio' name="selectname" />
                            <label>Speech Recognition</label>
                            <CheckCircleOutlineIcon className={styles.nonChk} /> <CheckCircleIcon className={styles.chkd} />
                        </div> */}
                        <div className={styles.ddItem}>
                            <input checked={services==='translation'} onChange={()=>navigate('/translation')} type='radio' name="selectname" />
                            <label>Translation</label>
                            <CheckCircleOutlineIcon className={styles.nonChk} /> <CheckCircleIcon className={styles.chkd} />
                        </div>
                        <div className={styles.ddItem}>
                            <input checked={services==='transliteration'} onChange={()=>navigate('/transliteration')} type='radio' name="selectname" />
                            <label>Machine Transliteration</label>
                            <CheckCircleOutlineIcon className={styles.nonChk} /> <CheckCircleIcon className={styles.chkd} />
                        </div>
                    </div>
                ) : null}
            </div>
        </ClickAwayListener>
    );
}

export default Select