import * as Yup from 'yup';

export const SignUpSchema = Yup.object({
    name: Yup.string().matches(/^[A-Za-z ]*$/, 'Name must contain only alphabets and space characters')
        .trim()
        .required('Name is required'),
    email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    password: Yup.string()
        .min(8, 'Password must be at least 8 characters long')
        .max(20, 'Password cannot exceed 20 characters'),
    confirm_password: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm password is required'),
    age: Yup.string().required(),
    gender: Yup.string().oneOf(['male', 'female'], 'Invalid gender').required('Gender is required'),

})

export const LoginSchema = Yup.object({
    email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    password: Yup.string()
        .min(8, 'Password must be at least 8 characters long')
        .max(20, 'Password cannot exceed 20 characters'),
})

export const ForgotPasswordSchema = Yup.object({
    email: Yup.string()
        .email('Invalid email address')
        .required('Email is required')
})

export const ChangePasswordSchema = Yup.object({
    password: Yup.string()
        .min(8, 'Password must be at least 8 characters long')
        .max(20, 'Password cannot exceed 20 characters'),
    confirm_password: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm password is required'),
})

export const UploadCorpusSchema = Yup.object({
    name: Yup.string().required(),
    description: Yup.string().required(),
    source: Yup.string().required(),
    category: Yup.string().required(),
    sub_category: Yup.string().required()
})