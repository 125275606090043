import React from 'react'
import ProgressBar from 'react-percent-bar'

function TopCatList() {
    return (
        <>
            <div className='px-4 py-3'>
                <h6>Top Categories</h6>
                <p className='text-xs'>List of top categories explored by people.</p>
            </div>
            <div className='tableWrap tc'>
                <table className='dashTable'>
                    <thead>
                        <tr>
                            <th>Category</th>
                            <th>Sub-category</th>
                            <th>Progress</th>
                        </tr>
                    </thead>  
                    <tbody>
                        <tr>
                            <td>Poetry</td>
                            <td>35</td>
                            <td>
                                <div className='inline-block pr-3'>
                                    <ProgressBar borderColor="#585858" percent="35" fillColor="#44A0DF" width="120px" height="6px" />
                                </div>
                                <strong className='text-primary'>35%</strong>
                            </td>
                        </tr>
                        <tr>
                            <td>Articles</td>
                            <td>35</td>
                            <td>
                                <div className='inline-block pr-3'>
                                    <ProgressBar borderColor="#585858" percent="86" fillColor="#44A0DF" width="120px" height="6px" />
                                </div>
                                <strong className='text-primary'>86%</strong>
                            </td>
                        </tr>
                        <tr>
                            <td>Novel</td>
                            <td>35</td>
                            <td>
                                <div className='inline-block pr-3'>
                                    <ProgressBar borderColor="#585858" percent="78" fillColor="#44A0DF" width="120px" height="6px" />
                                </div>
                                <strong className='text-primary'>78%</strong>
                            </td>
                        </tr>
                        <tr>
                            <td>Stories</td>
                            <td>35</td>
                            <td>
                                <div className='inline-block pr-3'>
                                    <ProgressBar borderColor="#585858" percent="64" fillColor="#44A0DF" width="120px" height="6px" />
                                </div>
                                <strong className='text-primary'>64%</strong>
                            </td>
                        </tr>
                        <tr>
                            <td>Poetry</td>
                            <td>35</td>
                            <td>
                                <div className='inline-block pr-3'>
                                    <ProgressBar borderColor="#585858" percent="35" fillColor="#44A0DF" width="120px" height="6px" />
                                </div>
                                <strong className='text-primary'>35%</strong>
                            </td>
                        </tr>
                        <tr>
                            <td>Articles</td>
                            <td>35</td>
                            <td>
                                <div className='inline-block pr-3'>
                                    <ProgressBar borderColor="#585858" percent="86" fillColor="#44A0DF" width="120px" height="6px" />
                                </div>
                                <strong className='text-primary'>86%</strong>
                            </td>
                        </tr>
                        <tr>
                            <td>Novel</td>
                            <td>35</td>
                            <td>
                                <div className='inline-block pr-3'>
                                    <ProgressBar borderColor="#585858" percent="78" fillColor="#44A0DF" width="120px" height="6px" />
                                </div>
                                <strong className='text-primary'>78%</strong>
                            </td>
                        </tr>
                        <tr>
                            <td>Stories</td>
                            <td>35</td>
                            <td>
                                <div className='inline-block pr-3'>
                                    <ProgressBar borderColor="#585858" percent="64" fillColor="#44A0DF" width="120px" height="6px" />
                                </div>
                                <strong className='text-primary'>64%</strong>
                            </td>
                        </tr>
                        <tr>
                            <td>Poetry</td>
                            <td>35</td>
                            <td>
                                <div className='inline-block pr-3'>
                                    <ProgressBar borderColor="#585858" percent="35" fillColor="#44A0DF" width="120px" height="6px" />
                                </div>
                                <strong className='text-primary'>35%</strong>
                            </td>
                        </tr>
                        <tr>
                            <td>Articles</td>
                            <td>35</td>
                            <td>
                                <div className='inline-block pr-3'>
                                    <ProgressBar borderColor="#585858" percent="86" fillColor="#44A0DF" width="120px" height="6px" />
                                </div>
                                <strong className='text-primary'>86%</strong>
                            </td>
                        </tr>
                        <tr>
                            <td>Novel</td>
                            <td>35</td>
                            <td>
                                <div className='inline-block pr-3'>
                                    <ProgressBar borderColor="#585858" percent="78" fillColor="#44A0DF" width="120px" height="6px" />
                                </div>
                                <strong className='text-primary'>78%</strong>
                            </td>
                        </tr>
                        <tr>
                            <td>Stories</td>
                            <td>35</td>
                            <td>
                                <div className='inline-block pr-3'>
                                    <ProgressBar borderColor="#585858" percent="64" fillColor="#44A0DF" width="120px" height="6px" />
                                </div>
                                <strong className='text-primary'>64%</strong>
                            </td>
                        </tr>
                    </tbody>                  
                </table>
            </div>
        </>
    )
}

export default TopCatList