import React from 'react'
import { useState , useEffect } from "react"
import "./shared/auth.css"
import SideBox from './shared/sideart-box'
import AuthCopy from './shared/auth-copyright'
import { Link } from 'react-router-dom'
import { BACKEND_URL } from '../config'
import { OtpInput } from './forgotPassOtp'
import LoaderForAll from '../components/loader-for-all'
import { useFormik } from 'formik'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ForgotPasswordSchema } from './authSchema'
import { useSendOtpRegister, useVerifyOtp } from './hooks'

function ForgetPassword() { 
    
    const [otpSent, setotpSent] = useState(false)
    const [load, setload] = useState(false)
    const sendOtpRegister_hook = useSendOtpRegister()


    
    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: ForgotPasswordSchema,
        onSubmit: (values) => {

            sendOtpRegister_hook.mutate(values)
        }
    });

    useEffect(() => {
      if (sendOtpRegister_hook.isSuccess) {
        setotpSent(true)
      }
    }, [sendOtpRegister_hook])
    

    return (
        <>
            <div className='authWrapper relative block md:flex'>
                <div className='flex-none w-full md:w-2/4'>
                    <SideBox />
                </div>                
                <div className='grow md:h-screen'>
                    <div className='p-8 md:px-12 h-full md:flex'>
                        
                        <div className='authModBox lg:w-96 max-w-full'>
                            <h2 className='uppercase text-2xl md:text-3xl mb-2'>Forget Password</h2>
                           
                           

                            { otpSent ? <OtpInput email={formik.values.email}/> :
                           <> 
                           {sendOtpRegister_hook.isLoading ? <LoaderForAll/> : 
                           <div className='mb-4' id="defaultScreen">
                                    <p className='text-xs mb-5'>Please enter your email id to get the verification code!</p>
                                    
                                    <form name='forgetPassword1'>
                                    <div className='mb-3'>
                                        <label htmlFor='email' className='text-primary w-full font-semibold'>Email Address</label>
                                        <input onBlur={formik.handleBlur} name='email' id="email" value={formik.values.email} onChange={formik.handleChange} type='text' className={`${(formik.errors.email && formik.touched.email) ? 'error' : ''} w-full inpFld`} placeholder='Enter here' />
                                        {formik.errors.email && formik.touched.email && <small className='error'>{formik.errors.email}</small>}
                                    </div>                            

                                        <div className='grid grid-cols-2 gap-4 pt-5'>
                                            <div>
                                                <button type='button' onClick={formik.handleSubmit} className='btnPrimary text-white font-semibold py-3 w-full'>
                                                    Send
                                                </button>
                                            </div>
                                            <div>
                                                &nbsp;
                                            </div>
                                        </div>
                                    </form>
                                </div> }
                                 </>}  

                        </div> 
                    </div>                                       
                </div>
                <div className='copyTxt text-sm p-3'>
                    <AuthCopy />
                </div> 
            </div>  

            <ToastContainer theme="colored" />          
        </>
    )
}

export default ForgetPassword