import React from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

const getOptions = (type) => ({
    legend: {
        layout: 'horizontal',
        align: 'right',
        verticalAlign: 'middle',
        itemMarginTop: 3,
        itemMarginBottom: 3,
        style: {
            fontSize: '8px',
          }
      },
    chart: {
        type,
        width: 360,
        height: 130,     
    },
    title: {
        text: '',
        align: 'top',
        style: {
          fontSize: '10px',
          //fill: '#ffffff',
        }
    },
    
     
    credits: {
        enabled: false
   },   
    yAxis: {
        title: {
            text: 'Values',
            style: {
                color: '#cdcdcd'
            }
        },
    },
    colors: ['#44A0DF', '#CA3559',],
    series: [
        {
            borderWidth: 0,
            xAxis: 0,
            data: [
                ['Correct words', 1],
                ['Incorrect words', 2],
            ],
            showInLegend: true,
            innerSize: '80%',
            dataLabels: {
                enabled: true,
                format: '{point.y:.1f}%',
                allowOverlap: false,
                style: {
                    fontSize: '10px',
                }
            },
            style: {
                fontSize: '8px',
                fontWeight: 300,
            }
          },
    ],
});

function ErrorsPercentage() {

    return (
        <>
            <div className='errorPie mx-auto p-4 lg:px-4 lg:py-0'>
                <h6 className='m-0'>Errors Percentage</h6>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={getOptions('pie')}
                />
            </div>

        </>
    )
}

export default ErrorsPercentage