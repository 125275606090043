import React, { useState , useEffect, useRef } from 'react'
import styles from '../shared/common.module.css'
import KeyboardAltOutlinedIcon from '@mui/icons-material/KeyboardAltOutlined'

import { useMutation } from 'react-query'
import axios from 'axios'

import {Codes , repeatCodes} from "../../components/keyMap"


function Test() {
  const [isOpened, setIsOpened] = useState(true);
  const [isToggled, setIsToggled] = useState(false);
  const [lang, setlang] = useState('punjabi')
  const [endpoint, setendpoint] = useState('pun2engtranslation')
  const [inpText, setinpText] = useState("")
  const [outputText, setoutputText] = useState("")
  const canvasRef = useRef(null);
  const [SpaceWidth, setSpaceWidth] = useState(0);
  const [textareaWidth, settextareaWidth] = useState('600px')

  const mutation = useMutation(
    {
      mutationFn: async () => {
        setoutputText("")
        return await axios.post(`https://nlp-toolkit.panjabi.ai/${endpoint}`, {
          "text": inpText
        }, {
          'Content-Type': 'application/json',
        })
      },
      onSuccess: (data) => {
          setoutputText(data?.data[endpoint])
      }


    }
  )




  function toggleKB() {
    setIsOpened(wasOpened => !wasOpened);
  }

  function toggleExpandB() {
    setIsToggled(wasToggled => !wasToggled);
  }

  function handleChange(e) {
    if (lang === 'punjabi' && e.nativeEvent.inputType === 'insertText' ) {
      var pos = e.target.selectionStart ; 
      var endPos = e.target.selectionEnd;
      if (inpText[pos-1] === Codes[e.nativeEvent.data] ){
        if ( repeatCodes[e.nativeEvent.data] !== inpText[inpText.length-1]){
          setinpText(p => p.slice(0,pos-1) + Codes[e.nativeEvent.data] + p.slice(endPos-1 , p.length))
        }
      }
      else{
        setinpText(p => p.slice(0,pos-1) + Codes[e.nativeEvent.data] + p.slice(endPos-1 , p.length))
      }
     
      setTimeout(() => {
        document.getElementById("editor").setSelectionRange(pos, pos);
      });

     
    }
    else{
      setinpText(e.target.value)
    }
    

  }

  const [selectedText, setSelectedText] = useState('');
    const [selectionStart, setSelectionStart] = useState(0);
    const [selectionEnd, setSelectionEnd] = useState(0);
    const [Lnumber, setLnumber] = useState([])
    const [lables, setlables] = useState([])

    const [Words, setWords] = useState([])

    function getLenInPx(str) {
      var ln = 0  
      for( let c of str){
        ln+=getCharacterWidth(c)
      }
      return ln 
      
    }
  
    const handleSelectionChange = (event) => {
      console.log(event)
      const textarea =  event.target;
  
      if (textarea.selectionStart == textarea.selectionEnd){
        setSelectedText('');
        setSelectionStart(textarea.selectionStart);
        setSelectionEnd(textarea.selectionStart);
        return 
      }
  
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      const text = textarea.value.substring(start, end);
      // var textareaWidth = textarea.clientWidth
  
      var Leftmargin = getLenInPx(textarea.value.substring(0, start))
      let width = getLenInPx(text)
      setlables(p => {
        return [...p , {Leftmargin , width}]
      })  
      WordFromText(inpText)

    };
  
  
   const [hidden, sethidden] = useState(true)
    

  
    function getCharacterWidth(character) {
      const charSizer = document.createElement("div");
      charSizer.style.position = "absolute";
      charSizer.style.visibility = "hidden";
      charSizer.style.width = "auto";
      charSizer.style.font = getComputedStyle(document.getElementById("editor")).font;
  
      document.body.appendChild(charSizer);
      charSizer.textContent = character;
      if (character === ' '){
        return SpaceWidth
        
      }
      else{
  
        var width = charSizer.offsetWidth;
      }
      document.body.removeChild(charSizer);
      return width;
    }
  

    function getSpaceWidth(testString) {
        const textarea = document.getElementById('editor');
       
        const testWidth = getTextWidth(testString, textarea.style.font);
        return testWidth;
      }
      
      function getTextWidth(text, font) {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        context.font = font;
        const width = context.measureText(text).width;
        return width;
      }
      
  
  
    
    useEffect(() => {
      
  
        
          

        setSpaceWidth(getSpaceWidth(' '))
      
    }, []);
  

    function WordFromText(inp) {

            var margin = 0
            var words = []

            function getLineNo(words, margin) {
                console.log(words)
                if (words.length ===0){
                    return [0, margin]
                }
                else{
                    if (margin >textareaWidth){
                        return [words[words.length-1].lineNo+1, 0]
                    }
                    else{

                        return [words[words.length-1].lineNo, margin]
                    }
                }
            }

            var word_start = false 
            var word = ''
            var width = 0
            var start = 0;
            var stop = 0;
            var margin_left = 0 
            var lineNo = 0
            var lable = ''
            console.log(inp)
            for (var i =0 ; i<inp.length;i++){
                margin += getCharacterWidth(inp[i])
                if (inp[i] !==' '){
                    if (word_start === false){
                        word = inp[i]
                        const [ln , mg] = getLineNo(words, margin)
                        lineNo = ln
                        

                        margin_left = mg - getCharacterWidth(inp[i])
                        
                        width = getCharacterWidth(inp[i])
                        margin = mg
                        start = i 
                        word_start = true
                    }
                    else{
                        word += inp[i]
                        width += getCharacterWidth(inp[i])
                        
                        const [ln, mg] = getLineNo(words, margin)
                        lineNo  = ln
                        if (mg ===0){
                            margin_left = mg
                        }
                        
                        margin = mg
                        }
                    }

                    else{
                        if (word_start) {
                            stop = i-1
                            lable= ''
                            words.push({
                                word, 
                                lineNo,
                                margin_left, 
                                width,
                                start,
                                stop,
                                lable
                            })
                            word_start= false
                            word = ''
                            width = 0
                            
                        }
                        else{
                            //

                        }


                    }

                }
                if (word_start) {
                    stop = i-1
                    lable= ''
                    words.push({
                        word, 
                        lineNo,
                        margin_left, 
                        width,
                        start,
                        stop,
                        lable
                    })
                }

                console.log(words)
                
                
                setWords(words)
            }



  return (
    <>
   
        

          <div className={`${styles.transBox} mb-3 ${lang != 'punjabi' ? 'h-[550px]' : 'h-700'}`}>
            <br/>
            <br/>
            <br/>
            <button onClick={()=> WordFromText(inpText)}>MAP</button>
            <button onClick={()=> sethidden(p=> !p)}>BTn</button>

            <div className='block lg:flex h-full'>
              <div className='lg:w-2/3 h-1/2 lg:h-full overflow-hidden'>
                <div className={`fstBox ${isOpened ? 'h-[200px] lg:h-[444px]' : 'h-full'} ${lang != 'punjabi' ? 'lg:h-[700px]' : ''}`}>
                  <div className={`h-full ${styles.textAreaPne}`}>
                   
                   
                    <div className='flex h-full p-4 overflow-auto'>
                      {/* Index */}
                      <div className='flex-none opacity-40 leading-relaxed w-[2rem]'>
                        <div>1</div>
                        <div>&nbsp;</div>
                        <div>3</div>
                        <div>4</div>
                      </div>
                      <div className='flex-1 relative'>
                        <textarea style={{ maxWidth: textareaWidth }} id="editor" className='p-0 absolute bg-red-300' value={inpText} onChange={handleChange}  placeholder='Add the text here'></textarea>
                      
                        {/* <div id="cursor-position" style={{ position: 'absolute', border: '1px solid green', backgroundColor: 'green' }}></div> */}
        
                        {Words.length !==0 && Words.map((itm , ind) => {
                           return <div style={{marginLeft:`${itm.margin_left}px` ,  border:'1px solid red' , width: `${itm.width}px`, height: '18px' , position: 'absolute' , display: `${hidden ? 'none' : 'inline'}` }}></div>
                            
                          })}
                        
                      </div>
                    </div>                    
                    {lang =='punjabi' ? 
                    <div className={`flex justify-between p-3 ${styles.btnHldr}`}>
                    
                      <div>
                        <button className={styles.kbtoggleBtn} onClick={toggleKB} title='Toggle Keyboard'><KeyboardAltOutlinedIcon /></button>
                      </div> 
                      <div>
                        <button onClick={mutation.mutate} className='px-4 btnPrimary text-white font-semibold py-2 lg:d-inline-block'>{mutation.isLoading ? 'loading...' : 'Test' }</button>
                      </div>
                    </div> : 
                    <div className={styles.nonPunjabiBtnHldr}>
                    <button onClick={mutation.mutate} className='px-4 btnPrimary text-white font-semibold py-2 lg:d-inline-block'>{mutation.isLoading ? 'loading...' : 'Test' }</button>
                  </div> }

                  </div>
                </div>
                
                
                
               
              </div>
              
            </div>
          </div>

         

         
    </>
  )
}

export default Test