import React, { useState, useEffect } from 'react'
import AuthCopy from '../auth/shared/auth-copyright'
import KeyboardAltOutlinedIcon from '@mui/icons-material/KeyboardAltOutlined'
import styles from './shared/common.module.css'
import NewKeyboard from '../components/newKeyboard'

function Dictionary() {
  const [isOpened, setIsOpened] = useState(false);
  const [inpText, setinpText] = useState("")

  useEffect(() => {
    NewKeyboard()

    return () => {
      let el = document.getElementById("editor")
      if (el?.parentNode) {

        el.parentNode.replaceChild(el.cloneNode(true), el)
      }
    }
  }, [])

  function toggleKB() {
    setIsOpened(wasOpened => !wasOpened);
  }

  return (
    <>
      <div className='pageWrap lightWhite withCopy'>

        <div className={`${styles.dictPageWrap} pt-10 px-4 lg:px-0 lg:pt-20`}>
          <div className={`${styles.deepNavyBox} ${styles.dicTxtFld} px-4 py-3 mb-2`}>
            <div className='flex justify-between'>
              <div className="inpTrans">
                <input type='text'
                  id="editor" value={inpText}
                  onChange={(e) => setinpText(e.target.value)}
                  placeholder='Enter here'
                />
              </div>
              <div>
                <button className={styles.kbtoggleB} onClick={toggleKB} title='Toggle Keyboard'><KeyboardAltOutlinedIcon /></button>
              </div>
            </div>
          </div>
          <p className={`${styles.tmuted} text-xs pl-3`}>(Example: ਸਤ ਸ੍ਰੀ ਅਕਾਲ/ ਹੈਲ or Hello/Hi)</p>

          <div className='py-8 text-center'>
            <button type='button' className='btnPrimary text-white font-semibold px-5 py-3 mx-2'>Search</button>
            <button type='button' className='btnOutline text-primary font-semibold px-5 py-3 mx-2'>Clear</button>
          </div>

          {/* Results */}
          <div className='py-4 lg:py-7'>
            <p className={`${styles.tmuted} text-md pb-2`}><strong>Results:</strong></p>
            <div className={`${styles.deepNavyBox} p-4`}>
              <p>Hello / Hi</p>
              <p>ਸਤ ਸ੍ਰੀ ਅਕਾਲ. / ਹੈਲੋ.<br /> (Sata sri akala. / Hailo.)</p>
            </div>
          </div>

          {/* Suggestions */}
          <div className='py-4 lg:py-7'>
            <p className={`${styles.tmuted} text-md pb-2`}><strong>Suggestions related to search:</strong></p>
            <div className={`${styles.tmuted} grayBox rounded-xl`}>
              <div className='p-4 bdrBtm'>
                <p>How are you? <br /> ਤੁਸੀ ਕਿਵੇਂ ਹੋ? <br /> (Tusi kiven ho?)</p>
              </div>
              <div className='p-4 bdrBtm'>
                <p>How are you? <br /> ਤੁਸੀ ਕਿਵੇਂ ਹੋ? <br /> (Tusi kiven ho?)</p>
              </div>
              <div className='p-4 bdrBtm'>
                <p>How are you? <br /> ਤੁਸੀ ਕਿਵੇਂ ਹੋ? <br /> (Tusi kiven ho?)</p>
              </div>
            </div>
          </div>

        </div>




        <div className={`${styles.keyBoardFloat} ${isOpened ? 'block' : 'hidden'}`}>
          <div id="keyboard"></div>
        </div>



        <div className='copyFloat'><AuthCopy /></div>
      </div>
    </>
  )
}

export default Dictionary;