import React from 'react'
import SliderPlay from '@mui/material/Slider';
import styles from '../../shared/common.module.css'
// Slider's imports
import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function SpeechsActions() {

    var settings = {
        dots: false,
        infinite: false,
        draggable: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
    }

    const marks = [
        {
            value: 0,
            label: '00:00',
        },
        {
            value: 100,
            label: '10:00',
        },
    ];

    return (
        <>
            <div className='actions grayBox rounded-xl'>
                <Slider {...settings}>
                    {/* Slider Loop (Index 0) */}
                    <div>
                        <div className={`${styles.actWrap} p-3`}>
                            <div className='block lg:flex gap-4'>
                                <div className='flex-1 mb-3 lg:mb-0'>
                                    <p className={`${styles.capt} mb-1`}>Speak and record the words above, to ensure its accuracy.</p>
                                    <div className={`${styles.recordsBtns} flex rounded-xl`}>
                                        <div className='flex-1'>
                                            <button type='button' className={`${styles.recBtn} flex w-full h-full items-center`} title="Record">
                                                <div className='flex-none w-6'><img src="/svg/mic-blue-icon.svg" alt="" /></div>
                                                <div className='flex-1 text-primary text-left pl-3'>
                                                    <p>00:00:00</p>
                                                    <p>Start Recording</p>
                                                </div>
                                            </button>
                                        </div>
                                        <div className='flex-none w-14 flex h-full items-center text-center'>
                                            <button type='button' className={`${styles.recOthBtn} ${styles.undo}`} title="Undo"></button>
                                        </div>
                                        <div className='flex-none w-14 flex h-full items-center text-center'>
                                            <button type='button' className={`${styles.recOthBtn} ${styles.delete}`} title="Remove"></button>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex-1 mb-3 lg:mb-0'>
                                    <p className={`${styles.capt} mb-1`}>Play and listen the recording, to ensure its accuracy.</p>
                                    <div className={`${styles.playBtnsBx} flex rounded-xl p-2`}>
                                        <div className='flex w-full items-center'>
                                            <div className='flex-none w-14'>
                                                <button type='button' className={styles.playBtn} title="Play"></button>
                                            </div>
                                            <div className='flex-1 px-4 pt-1'>
                                                <SliderPlay defaultValue={50} aria-label="Default" valueLabelDisplay="auto" marks={marks} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex-none w-full lg:w-24 lg:pt-[20px]'>
                                    <button className='w-full btnPrimary text-white font-semibold py-5 d-block'>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Slider Loop (Index 1) */}
                    <div>
                        <div className={`${styles.actWrap} p-3`}>
                            <div className='block lg:flex gap-4'>
                                <div className='flex-1 mb-3 lg:mb-0'>
                                    <p className={`${styles.capt} mb-1`}>Speak and record the words above, to ensure its accuracy.</p>
                                    <div className={`${styles.recordsBtns} flex rounded-xl`}>
                                        <div className='flex-1'>
                                            <button type='button' className={`${styles.recBtn} flex w-full h-full items-center`} title="Record">
                                                <div className='flex-none w-6'><img src="/svg/mic-blue-icon.svg" alt="" /></div>
                                                <div className='flex-1 text-primary text-left pl-3'>
                                                    <p>00:00:00</p>
                                                    <p>Start Recording</p>
                                                </div>
                                            </button>
                                        </div>
                                        <div className='flex-none w-14 flex h-full items-center text-center'>
                                            <button type='button' className={`${styles.recOthBtn} ${styles.undo}`} title="Undo"></button>
                                        </div>
                                        <div className='flex-none w-14 flex h-full items-center text-center'>
                                            <button type='button' className={`${styles.recOthBtn} ${styles.delete}`} title="Remove"></button>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex-1 mb-3 lg:mb-0'>
                                    <p className={`${styles.capt} mb-1`}>Play and listen the recording, to ensure its accuracy.</p>
                                    <div className={`${styles.playBtnsBx} flex rounded-xl p-2`}>
                                        <div className='flex w-full items-center'>
                                            <div className='flex-none w-14'>
                                                <button type='button' className={styles.playBtn} title="Play"></button>
                                            </div>
                                            <div className='flex-1 px-4 pt-1'>
                                                <SliderPlay defaultValue={50} aria-label="Default" valueLabelDisplay="auto" marks={marks} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex-none w-full lg:w-24 lg:pt-[20px]'>
                                    <button className='w-full btnPrimary text-white font-semibold py-5 d-block'>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>
        </>
    )
}

export default SpeechsActions