import React , {useState} from 'react'
import styles from '../../shared/common.module.css'
import { useFormik } from 'formik';
import { UploadCorpusSchema } from '../../../auth/authSchema'
import { useMutation, useQuery } from 'react-query'
import { BACKEND_URL } from '../../../config'
import axios from 'axios'
import { useSelector } from 'react-redux'
import LoaderForAll from '../../../components/loader-for-all'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from 'react-router-dom'


export const ListObject = ({ itm , setdelete_s3 , setisDeleted , setpop }) => {
    const [isOpened, setisOpened] = useState(false)
    const Data = useSelector(state => state)
    const [file, setfile] = useState(null)

    const mutation = useMutation(
        {
          mutationFn: async (data) => {
            console.log(data , "DATAA")
            
            return await axios.post(BACKEND_URL + `/update_corpus_metadata`, data , {
               headers: {  Authorization: "Bearer " + Data.auth["access_token"] }
            }
            ).then(res => {
                if (res.status===200){

                    toast.success("Updated")
                    // view_corpuses.refetch()
                    // setnewupload(false)
                }
                else{
                    toast.error("Error Occured")
                }
            } )
          },
          onSuccess: (data) => {
              console.log(data)
          }
        }
      )


       let formik = useFormik({
            initialValues: {
                name: itm.name,
                description: itm.description,
                source: itm.source,
                category: itm.category,
                sub_category: itm.sub_category,
                s3_uri: itm.s3_uri
            },
            validationSchema: UploadCorpusSchema,
            onSubmit: (values) => {

                var fd = new FormData()
                fd.append("name", values.name)
                fd.append("category", values.category)
                fd.append("sub_category", values.sub_category)
                fd.append("source", values.source)
                fd.append("description", values.description)
                fd.append("s3_uri", values.s3_uri)

                mutation.mutate(fd)
            }
        })

        function formatDate(inputDate) {
            const months = [
                "January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];
            var date = inputDate.split(' ')
            var d = date[0]
            var t = date[1]
            d = d.split('/')
            t = t.split(':')
            var din = d[0]
            var month = months[parseInt(d[1])]
            var year = d[2]
            var tm = parseInt(t[0]) > 12 ? 'pm' : 'am'
            var hr = parseInt(t[0]) > 12 ? String(parseInt(t[0]) - 12) : t[0]
            var mn = t[1]
            var ans = din + ' ' + month + ' ' + year + ', ' + hr + ':' + mn + ' ' + tm
            return ans
        }




        const updateFile = useMutation(
            {
              mutationFn: async (data) => {
                console.log(data , "DATAA")
                
                return await axios.post(BACKEND_URL + `/update_corpus_data`, data , {
                   headers: {  Authorization: "Bearer " + Data.auth["access_token"] }
                }
                ).then(res => {
                    if (res.status===200){
    
                        toast.success("File Updated")
                        // view_corpuses.refetch()
                        // setnewupload(false)
                    }
                    else{
                        toast.error("Error Occured")
                    }
                } )
              },
              onSuccess: (data) => {
                  console.log(data)
              }
            }
          )



    function handleFileUpload(e) {
        console.log("here")
        // setfile(e.target.files[0])

        let fd = new FormData()
        fd.append("s3_uri" , itm.s3_uri)
        fd.append("file" ,e.target.files[0] )
        updateFile.mutate(fd)

    }

    


    return (
        <>

            <div className='tBodyRow flex items-center py-2 rounded-md'>
                <div className='flex-1 px-3'>
                    <Link to="">{itm.name}</Link>
                </div>
                <div className='flex-1 px-3'>
                    <p>{formatDate(itm.last_updated_on)}</p>
                </div>
                <div className='flex-1 px-3'>
                    <p>{itm.category}</p>
                </div>
                <div className='flex-1 px-3'>
                    <p>{itm.sub_category}</p>
                </div>
                <div className='flex-1 px-3 leading-none text-right'>
                    <button type='btn' onClick={()=> setisOpened(p => !p)} className='btnView mx-2' title='View'></button>
                    <button onClick={() => {
                        setdelete_s3(itm.s3_uri)
                        setpop(true)
                    }} type='btn' className='btnDel mx-2' title='Remove'></button>
                </div>
            </div>



            {isOpened && (
                <div className="boxContent">
                    <div className='pt-4 text-right'>
                        <button className={`${styles.uploadBtn} ml-4`}>
                            <span></span>Upload
                            <input value={file} onChange={handleFileUpload} type='file' title='' />
                        </button>
                        <button className={`${styles.downloadBtn} ml-4`}><span></span>Download</button>
                    </div>
                    <div className='p-4 lg:p-6'>

                    <form className={`${styles.bdrFields} block max-w-screen-md mx-auto`}>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-10 mb-4'>
                            <div>
                                <label className='block mb-2'>Name</label>
                                <input name='name' id="name" value={formik.values.name} onChange={formik.handleChange} type='text' placeholder='Enter here' required />
                            </div>
                           
                        </div>
                        <div className='grid grid-cols-1 md:grid-cols-3 gap-4 lg:gap-10 mb-4'>
                            <div>
                                <label className='block mb-2'>Source of Data</label>
                                <input name='source' id="source" value={formik.values.source} onChange={formik.handleChange} type='text' placeholder='Enter here' required />
                            </div>

                            <div>
                                <label className='block mb-2'>Category of Data</label>
                                <input name='category' id="category" value={formik.values.category} onChange={formik.handleChange} type='text' placeholder='Enter here' required />
                            </div>

                            <div>
                                <label className='block mb-2'>Sub Category of Data</label>
                                <input name='sub_category' id="sub_category" value={formik.values.sub_category} onChange={formik.handleChange} type='text' placeholder='Enter here' required />
                            </div>

                        </div>
                        <div className='grid grid-cols-1 gap-4 lg:gap-10 mb-4'>
                            <div>
                                <label className='block mb-2'>Description of Data</label>
                                <input name='description' id="description" value={formik.values.description} onChange={formik.handleChange} type='text' placeholder='Enter here' required />
                            </div>
                        </div>
                        {/* <div className='mb-5'>
                            <FormControlLabel required control={<Checkbox sx={{
                                color: grey[400],
                                '&.Mui-checked': {
                                    color: grey[200],
                                },
                            }} />} label="The content shared above is licensed." />
                        </div> */}
                        <div>
                            <button onClick={formik.handleSubmit} className='btnPrimary text-white font-semibold py-3 px-6'>
                                Submit
                            </button>
                        </div>
                    </form>
                    </div>
                </div>
            )}


        </>
    )
}
