import { useMutation } from 'react-query'
import { ML_BACKEND } from '../../config'
import axios from 'axios'

export const useGetResult = () => {
    return useMutation(
        {
          mutationFn: async ({inpText , endpoint}) => {
            // setoutputText("")
          
            return await axios.post(`${ML_BACKEND}/${endpoint}`, {
              "text": inpText
            }, {
              'Content-Type': 'application/json',
            })
            
          },
          onSuccess: (data) => {
            //   setoutputText(data?.data[endpoint])
          }
        }
      )
}