import React, { useState, useEffect } from 'react'
import styles from '../../shared/common.module.css'
import { Link, useNavigate } from 'react-router-dom'
import { DeleteCorpusPop } from './deleteCorpuspop';
import { toast } from 'react-toastify';
import CorpusContent from './corpus-content';
import { ListObject } from './listObject';

function CorpusListing({ corpaList, view_corpuses_hook }) {
    const navigate = useNavigate();


    const [pop, setpop] = useState(false)
    const [delete_s3, setdelete_s3] = useState(null)
    const [isDeleted, setisDeleted] = useState(false)

    useEffect(() => {
        if (isDeleted) {
            view_corpuses_hook.refetch()
            toast.success("Deleted")
            setisDeleted(false)
        }
    }, [isDeleted])



    return (
        <>
            <div className='grayBox rounded-xl p-5'>
                <div className='pb-3 flex justify-between items-center text-right'>
                    <div>
                        <button type="button" className='backTxtBtn' onClick={() => navigate('/contribution')}>Back</button>
                    </div>
                    <div>
                        <button onClick={() => navigate('/upload_new_corpus')} type='button' className='btnPrimary text-white font-semibold py-3 px-6'>
                            Upload File
                        </button>
                    </div>                    
                </div>
                <div className='tableListWrap'>
                    <div className='contTable text-xs min-w-[900px] px-3'>
                        <div className='tableHead gray flex items-center py-2'>
                            <div className='flex-1 px-3'>File Title</div>
                            <div className='flex-1 px-3'>Upload Date and Time</div>
                            <div className='flex-1 px-3'>Category</div>
                            <div className='flex-1 px-3'>Sub Category</div>
                            <div className='flex-1 px-3'>&nbsp;</div>
                        </div>
                        <div className='tableBody'>
                            {/* Table Row Loop */}
                            {corpaList.map((itm) => {
                                return (
                                    <div key={itm.s3_uri}>
                                    <ListObject key={itm.s3_uri} itm={itm} setdelete_s3={setdelete_s3} setisDeleted={setisDeleted} setpop={setpop}/>
                                    </div>
                                )

                            })}

                            

                        </div>
                    </div>
                </div>
            </div>

            {pop ? <DeleteCorpusPop setpop={setpop} s3_uri={delete_s3} set_s3uri={setdelete_s3} setisDeleted={setisDeleted} /> : null}
        </>
    )
}

export default CorpusListing