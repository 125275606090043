import React from 'react'
import styles from '../../shared/common.module.css'
import pfont from '../../shared/pfont.module.css'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import ShareIcon from '@mui/icons-material/Share'
import HistoryIcon from '@mui/icons-material/History'
import VolumeUpIcon from '@mui/icons-material/VolumeUp'

function SpeechTextPane() {

    return (
        <>
            <div className={`${styles.textAreaPne} h-[450px]`}>
                {/* If category and file are not selected then print this below box */}
                <div className={`${styles.nonSelectedTAreaBox} flex items-center justify-center h-full`}>
                    <div className="text-center">                        
                        <div className="pb-3">
                            <img className="inline-block" src="/svg/non-selected-box-file-ico.svg" alt="" />
                        </div>
                        <p>Select a category and file</p>
                    </div>
                </div>

                {/* <textarea className={pfont.panjabiFont} placeholder='Text area of speech'></textarea>
                <div className={`flex justify-end px-3 pb-3 ${styles.btnHldr}`}>
                    <div className='px-1'><button className={styles.optActBtn} title='Volume'><VolumeUpIcon /></button></div>
                    <div className='px-1'><button className={styles.optActBtn} title='History'><HistoryIcon /></button></div>
                    <div className='px-1'><button className={styles.optActBtn} title='Share'><ShareIcon /></button></div>
                    <div className='px-1'><button className={styles.optActBtn} title='Copy'><ContentCopyIcon /></button></div>
                    <div className='px-1'><button className={styles.optActBtn} title='Download'><FileDownloadIcon /></button></div>
                </div> */}
            </div>
        </>
    )
}

export default SpeechTextPane