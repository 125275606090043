import React , {useEffect , useState} from 'react'
import styles from './keyboard.module.css'
import { getcharacterFromKeyCode } from './keyMap'

function KeyBoard() {

    const [shiftKey, setshiftKey] = useState(false)
    const [altKey, setaltKey] = useState(false)

    
    


    function handleBtnPress(e) {
        if (e.target.id == 'r4k1'){
            setshiftKey(p => !p)
        }
        else{
            let key = parseInt(e.target.id.split('_')[1])
            
        }
        
    }

    

    return (
        <>
            <div className={styles.keyboardWrap}>
                <div className={styles.keyboard}>
                    {/* Row 1 */}
                    <div className='flex gap-2 mb-2'>
                        <button id='r1k1_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r1k1}></button>
                        <button id='r1k2_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r1k2}></button>
                        <button id='r1k4_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r1k4}></button>
                        <button id='r1k5_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r1k5}></button>
                        <button id='r1k6_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r1k6}></button>
                        <button id='r1k7_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r1k7}></button>
                        <button id='r1k8_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r1k8}></button>
                        <button id='r1k9_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r1k9}></button>
                        <button id='r1k10_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r1k10}></button>
                        <button id='r1k11_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r1k11}></button>
                        <button id='r1k12_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r1k12}></button>
                        <button id='r1k13_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r1k13}></button>
                        <button id='r1k14_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r1k14}></button>
                    </div>
                    {/* Row 2 */}
                    <div className='flex gap-2 mb-2'>
                        <button id='r2k1_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r2k1}></button>
                        <button id='r2k2_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r2k2}></button>
                        <button id='r2k4_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r2k4}></button>
                        <button id='r2k5_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r2k5}></button>
                        <button id='r2k6_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r2k6}></button>
                        <button id='r2k7_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r2k7}></button>
                        <button id='r2k8_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r2k8}></button>
                        <button id='r2k9_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r2k9}></button>
                        <button id='r2k10_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r2k10}></button>
                        <button id='r2k11_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r2k11}></button>
                        <button id='r2k12_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r2k12}></button>
                        <button id='r2k13_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r2k13}></button>
                        <button id='r2k14_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r2k14}></button>
                    </div>
                    {/* Row 3 */}
                    <div className='flex gap-2 mb-2'>
                        <button id='r3k1_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r3k1}></button>
                        <button id='r3k2_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r3k2}></button>
                        <button id='r3k4_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r3k4}></button>
                        <button id='r3k5_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r3k5}></button>
                        <button id='r3k6_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r3k6}></button>
                        <button id='r3k7_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r3k7}></button>
                        <button id='r3k8_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r3k8}></button>
                        <button id='r3k9_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r3k9}></button>
                        <button id='r3k10_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r3k10}></button>
                        <button id='r3k11_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r3k11}></button>
                        <button id='r3k12_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r3k12}></button>
                        <button id='r3k13_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r3k13}></button>
                    </div>
                    {/* Row 4 */}
                    <div className='flex gap-2 mb-2'>
                        <button id='r4k1_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r4k1}></button>
                        <button id='r4k2_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r4k2}></button>
                        <button id='r4k3_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r4k3}></button>
                        <button id='r4k4_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r4k4}></button>
                        <button id='r4k5_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r4k5}></button>
                        <button id='r4k6_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r4k6}></button>
                        <button id='r4k7_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r4k7}></button>
                        <button id='r4k8_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r4k8}></button>
                        <button id='r4k9_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r4k9}></button>
                        <button id='r4k10_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r4k10}></button>
                        <button id='r4k11_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r4k11}></button>
                        <button id='r4k12_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r4k12}></button>                        
                    </div>
                    {/* Row 5 */}
                    <div className='flex gap-2 mb-2'>
                        <button id='r5k1_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r5k1}></button>
                        <button id='r5k2_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r5k2}></button>
                        <button id='r5k3_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r5k3}></button>
                        <button id='r5k4_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r5k4}></button>
                        <button id='r5k5_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r5k5}></button>
                        <button id='r5k6_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r5k6}></button>
                        <button id='r5k7_' onClick={(e)=> handleBtnPress(e)} type='button' className={styles.r5k7}></button>                       
                    </div>
                </div>
            </div>
        </>
    )
}

export default KeyBoard