import React from 'react'
import { Link } from "react-router-dom"
import { LogoutPopup } from '../auth/logoutPopup'
import { useState } from 'react'

function AccButtons() {

    const [pop, setpop] = useState(false)

    return (
        <>
            {/* <Link
                to="/"
                className="px-4 btnPrimary text-white font-semibold py-2 lg:d-inline-block lg:mx-2"
            >
                Log in
            </Link> */}
            <button
                onClick={()=> setpop(true)}
                className="px-0 btnTxt text-primary font-semibold py-2 lg:d-inline-block lg:mx-2"
            >
                Logout
            </button>
            {pop ? 
            <LogoutPopup setpop={setpop}/> : null }
        </>
    )
}

export default AccButtons